import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../../../services/auth.service";
import { setLocalStorageValue } from "../../../utils/localStorage.utils";

export const loginByWallet = createAsyncThunk(
    "auth/loginByWallet",
    async(values) =>{
        console.log("Values in slice: ",values)
        const response = await authService.login(values.address, values.data, values.originalMessage, values.originalDate)
        if(response){
            return response
        }
        else{
            return Promise.reject()
        }
    }
);


export const isLoginByToken = createAsyncThunk(
    "auth/isLoginByToken",
    async() => {
        const response = await authService.isLogin()
        if(response){
            return response.data
        }
        else{
            return Promise.reject()
        }
    }
);

const initialState = {
    isLoadingOk: false,
    isAuth: false,
    errors: "",
    user: {},
};


const authSlice = createSlice({
    name : "auth",
    initialState,
    reducers: {
        clearErrorMessage: (state) => {
            state.errors = "";
        },
        setIsAuth: (state) => {
            state.isAuth = !state.isAuth;
        },
    },
    extraReducers:(builder) => {
        builder.addCase(loginByWallet.pending,(state,action) => {
            state.isLoadingOk = true;
            state.errors = "";
            state.isAuth = false;
        });
        builder.addCase(loginByWallet.rejected,(state,action) => {
            state.isLoadingOk = false;
            state.errors = "You are not owner of this collection";
            state.isAuth = false;
            
        });
        builder.addCase(loginByWallet.fulfilled,(state,{payload}) =>{
            state.isLoadingOk = false;
            state.errors = "";
            state.isAuth = true;
            state.user = payload;
            
            setLocalStorageValue("ac_token",payload.token)

        });
        builder.addCase(isLoginByToken.pending,(state,action) =>{
            state.isLoadingOk = true;
            state.errors = "";
            state.isAuth = false;
        });
        builder.addCase(isLoginByToken.rejected,(state,action) =>{
            state.isLoadingOk = false;
            state.errors = "Token Invalid";
            state.isAuth = false;
        });
        builder.addCase(isLoginByToken.fulfilled,(state,{payload}) =>{
            state.isLoadingOk = false;
            state.errors = "";
            state.isAuth = true;
            state.user = payload
        })
    }
})

export const { clearErrorMessage, setIsAuth } = authSlice.actions;
export default authSlice.reducer