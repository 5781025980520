import React from 'react'
import styled from 'styled-components'
import CardEmerge from './CardEmerge'
import { useState } from 'react'
import { useEffect } from 'react'

const ContainerEmerge = styled.div`
display:flex;
align-items:center;
justify-content:flex-start;
width:100%;
height:20%;
margin-top:4%;
flex-direction:column;
@media (max-width: 820px) {
 height:35vh;
 justify-content:center;
     }
     @media (max-width: 375px) {
      height:45vh;
    
    } 
     @media (max-width: 325px) {
      height:75vh;
    
    }  
`
const ContainerTitle = styled.div`
color:white;
display:flex;
width:60vw;
justify-content:flex-start;
flex-direction:column;
@media (max-width: 820px) {
    width:90%;
       }
` 
const SubTitle = styled.h4`
color:white;
font-size:1.5rem;
text-shadow:  rgba(255,255,255,0.9) 0px 0px 6px; 
display:flex;
justify-content:flex-start;
@media (max-width: 820px) {
    font-size:1rem;
       }
` 
const Title = styled.h1`
color:white;
font-size:2.5rem;
text-shadow:  rgba(255,255,255,0.9) 0px 0px 6px; 
display:flex;
justify-content:flex-start;
margin-top:-4%;

@media (max-width: 800px) {
    margin-top:-8%;
    font-size:2rem;
       }
       @media (device-width: 768px) and (device-height: 1024px){   
        margin-top:-3%;
      }       
`

const ContainerRond = styled.div`


@media (max-width: 820px) {
display:flex;
margin-top:7%;
flex-direction:row;
margin-left:5%;
width:100%;
z-index:7;
overflow-y:hidden;
overflow-x:scroll;
height:100vh;
::-webkit-scrollbar {
  display: none;
}
scrollbar-width: none;
-ms-overflow-style: none;
   }
`

const CardListContainer = styled.div`

@media (max-width: 820px) {
flex-wrap: nowrap;
display: flex;
scroll-snap-type: both;
 flex-direction:column;
overflow-y:hidden;
align-items: center;
justify-content: center;
scroll-behavior:smooth;
margin-top: -5%;
height:250px;
width: 99.9%;
overflow-x:scroll;
scrollbar-width: none; 
-ms-overflow-style: none;
&::-webkit-scrollbar {
display:none;
}

&::-webkit-scrollbar-thumb {
  display: none !important;
}
}

`

const ContainerB = styled.div`
display:grid;
justify-content:flex-start;
grid-template-columns:repeat(5, 1fr);
width:100%;


    `
const ContainerB2 = styled.div`
    display:flex;
    justify-content:flex-start;
    width:52vw;
    flex-direction:row;
    text-align:center;

    
   
        `     
const Emerge = () => {
    const [isMobile, setIsMobile] = useState(() => window.innerWidth <= 820);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 820);
      };
  
      handleResize(); // Check on initial render
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

  return (
    <ContainerEmerge>
        <ContainerTitle>
            <SubTitle>MY</SubTitle>
            <Title> THE EMERGE</Title>
        </ContainerTitle>
        {isMobile ? (
            <CardListContainer>
                <ContainerRond>
                <CardEmerge/>
                </ContainerRond>
            </CardListContainer>
      ) : (
        <ContainerB2>
        <ContainerB>
        <CardEmerge/>
         </ContainerB>
         </ContainerB2>
              

        )}
            
    </ContainerEmerge>
  )
}

export default Emerge 