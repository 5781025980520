import React from 'react'
import styled from 'styled-components'

import Vector2 from "../Assets/Vector2.png"
import { Card } from './CardList'
import CardBackground1 from "../Assets/Card0.png";
import CardBackground2 from "../Assets/Card1.png";
import CardBackground3 from "../Assets/Card2.png";
import CardBackground4 from "../Assets/Card3.png";
import CardBackground5 from "../Assets/Card4.png";


const CardCardContainer= styled.div`
    display:flex;
    margin-top:7%;
    flex-direction:row;
    margin-left:70%;
    width:150%;
    z-index:7;
    overflow-y:hidden;
    overflow-x:scroll;
    height:100vh;
    ::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;

    @media (max-width: 820px) {
      width:450%;
      margin-left:360%;
      
        }
         

    
`


const CardsContainer = styled.div`
    margin-top:0%;
    overflow-x:hidden;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
 
    @media (max-width: 820px) {
      margin-top:-30%;
      overflow-x: hidden;
      position:relative;
          }
    @media (max-width: 325px) {
      margin-top:-20%;
         }  
             
      
            
`


const CardsImageVector = styled.img`
    position:absolute;
    top:72%;
    z-index:5;
    display:flex;
    align-items:center;
    justify-content:center;
    @media (max-width: 820px) {
      overflow-x:hidden;
      transform:scale(0.6);
      top:-23%;
      
    }
   
`
const TitleCards = styled.h1`
    display:flex;
    justify-content:center;
    width:65%;
    font-size:3.9rem;
    line-height:1.1em;
    text-shadow:  rgba(255,255,255,0.9) 0px 0px 6px; 
    text-align:center;
    color:white;
    flex-wrap: wrap;
    span {
      color: #02ff79; 
      white-space: nowrap;
      text-shadow:  #02ff79 0px 0px 6px; 
    
      
    }
    @media (max-width: 820px) {
       font-size:2rem;
       width:85%;
    
    }
    @media only screen and (min-width: 822px) and (max-width: 1366px) {    
      font-size:3.3rem;
      
    }
    `
const CardListContainer = styled.div`
    flex-wrap: nowrap;
    display: flex;
    scroll-snap-type: both;
     flex-direction:column;
    overflow-y:hidden;
    align-items: center;
    justify-content: center;
    scroll-behavior:smooth;
    margin-top: -5%;
    height: 700px;
    width: 99.9%;
    overflow-x:scroll;
    scrollbar-width: none; 
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
    display:none;
    }
  
    &::-webkit-scrollbar-thumb {
      display: none !important;
    }
    
    @media (max-width: 820px) {
      height: 570px;
      overflow-y:hidden;
      position:relative;
   }
    @media (device-width: 412px) and (device-height: 915px){
    height: 600px;
    } 
    @media (device-width: 320px) and (device-height: 658px){
     height: 600px;
         } 
    @media (device-width: 414px) and (device-height: 896px){
    height: 600px;
                  }   
    @media only screen and (device-width: 768px) and (device-height: 1024px)  {
      height: 800px;
    }
    @media only screen and (device-width: 820px) and (device-height: 1180px)  {
      height: 800px;
    } 
            
                                   

`
const TextSuplem = styled.p`
  display:flex;
  justify-content:center;
  color:white;
  font-weight:200;
  width:45%;
  font-size:1rem;
  line-height:1.1em;
  text-shadow:  rgba(255,255,255,0.9) 0px 0px 6px; 
  text-align:center;
  margin-top:-0.1%;
  @media (max-width: 820px) {
    width:84%;
 
 }
 @media (max-width: 325px) {
font-size:0.8rem;
            }  
`


const CardListContainer2 = styled.div`
    display:flex;
    align-items:center;
    flex-direction:row;
    width:100%;
    z-index:7;
    @media (max-width: 820px) {
      flex-direction:column;
      display:grid;
      grid-template-columns: repeat(2, 1fr);
      margin-left:0%;
   }

`


interface CardData  {
  chapter: string;
  title: string;
  date: string;
  lock: boolean;
  backgroundImage: string; 
};

const cardData: CardData[] = [
  {
    chapter: "CHAPTER 0" ,
    title: "GATEWAY TO THE FUTUR",
    date: "JUNE 7",
    lock: true,
    backgroundImage: CardBackground1, 
  },
  {
    chapter: "CHAPTER 1" ,
    title: "ITS ALL ABOUT QUESTING",
    date: "JUNE 14",
    lock: false,
    backgroundImage: CardBackground2, 
  },
  {
    chapter: "CHAPTER 2" ,
    title: "THE LEGEND OF HENRI DE GAYETTE",
    date: "JULY 24",
    lock: false,
    backgroundImage: CardBackground3, 
  },
 
  {
    chapter: "CHAPTER 3" ,
    title: "LETS DIVE TO THE CROCOVERSE",
    date: "SEPTEMBER 11",
    lock: false,
    backgroundImage: CardBackground4, 
  },
  {
    chapter: "CHAPTER 4" ,
    title: "FROM THE EMERGE TO...",
    date: "OCTOBER 31",
    lock:false,
    backgroundImage: CardBackground5, 
  },
 
];





const Cards = () => {
 
  return (
    <CardsContainer>
      <CardsImageVector src={Vector2}/>
        <TitleCards>EXPLORE THE HISTORY OF&nbsp;<span>LACOSTE</span>&nbsp;THROUGH TIME</TitleCards>
                <TextSuplem>As holders participate in various activities, their cards become more advanced,
                with new capabilities and powers in UNDW3
                </TextSuplem>
           <CardListContainer>
          <CardCardContainer>
          {cardData.map((card, index) => (
            <Card key={index} data={card}/>
          ))}
            </CardCardContainer>
          </CardListContainer> 
         
    </CardsContainer>
  )
}

export default Cards