import { useState } from "react";
import styled from "styled-components";
import Lock from "../Assets/IconLockCollect.png"
import Eclipse1 from "../Assets/ElemVert.png"


interface RoundProps {
  isActive?: boolean;
}

const Round = styled.div<RoundProps>`
  display:inline-block;
  width: 100%;
  height:95%;
  transform:scale(0.9);
  position: relative;
  
  
`;

const Image = styled.img<RoundProps>`
  width: 100%;
  height: 100%;
  object-fit:contain;
  filter: ${props => props.isActive ? 'none' : 'grayscale(100%)'};
  
`;


const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);

  
`;

const rounds = [
  {
    id: 1,
    isActive: true,
    image: Eclipse1 ,
  },
  {
    id: 2,
    isActive: true,
    image: Lock ,
  },
  {
    id: 3,
    isActive: true,
    image: Lock ,
  },
  {
    id: 4,
    isActive: true,
    image: Lock ,
  },
  {
    id: 5,
    isActive: true,
    image: Lock ,
  },
  {
    id: 6,
    isActive: true,
    image: Lock ,
   
  },
  {
    id: 7,
    isActive: true,
    image: Lock ,
   
  },
  {
    id: 8,
    isActive: true,
    image: Lock ,
   
  },
  {
    id: 9,
    isActive: true,
    image: Lock ,
   
  },
  {
    id: 10,
    isActive: true,
    image: Lock ,
   
  },
  {
    id: 11,
    isActive: true,
    image: Lock ,
    
  },
 
  {
    id: 12,
    isActive: true,
    image: Lock ,
  },
];

const RoundGrid = () => {
  const [roundsState, setRoundsState] = useState(rounds);

 

  return (
 
   <>
      <Container>
        {roundsState.map((round) => (
          <Round key={round.id} isActive={round.isActive}>
            {round.isActive && <Image src={round.image} isActive={round.isActive} />}
          </Round>
        ))}
      </Container>
    </>
  );
};
  
export default RoundGrid;
