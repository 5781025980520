import React from 'react'
import Layer from '../CollectionComponents/Layer'
import NavbarCollection from '../Navbar/NavbarCollection'
import FourElements from '../CollectionComponents/FourElements'
import NeedHelp from '../CollectionComponents/NeedHelp'
import FooterNotHome from '../Footer/FooterNotHome'

const Collection = () => {
  return (
    <>
    <Layer/>
    <NavbarCollection/>
    <FourElements/>
    <NeedHelp/>
    <FooterNotHome/>
    </>
  )
}

export default Collection