import React from 'react'
import styled from 'styled-components';
import {useState} from 'react';
import FooterNotHome from '../Footer/FooterNotHome'

type CardType = {
    num: string;
    id :string;
    imageSrc: string;
    isGood: boolean;
    points: string;
  }
  
  const cardsData: CardType[] = [
    { num: "#1", id: "ID DISCORD", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3456" },
    { num: "#2", id: "ID DISCORD", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: true, points: "3455" },
    { num: "#3", id: "ID DISCORD", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3453" },
    { num: "#4", id: "ID DISCORD", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3452" },
    { num: "#5", id: "ID DISCORD", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3451" },
    { num: "#6", id: "ID DISCORD", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3452" },
    { num: "#7", id: "ID DISCORD", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3452" },
    { num: "#8", id: "ID DISCORD", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3452" },
    { num: "#9", id: "ID DISCORD", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3452" },
  ];
  
  const CardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height:42vh;
    padding:10px;
    margin-top:10%;
    @media (max-width: 820px) {
    margin-top:15%;
    height:70vh;
    padding:0px;
         }
         @media (max-width: 325px) {
          height:50vh;
          margin-top:25%;
               }    
               @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
                margin-top:15%;
                     }            
   
  `;
  
  const CardsAll = styled.div`
    margin-top:-10%;
    align-items: center;
    flex-direction: column;
    display:flex;
    width: 60%;
    margin-right:-2%;
    @media (max-width: 820px) {
      width: 90%;
  
         }
  `;
  const Card = styled.div<{ isGood: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 6vh;
    margin:10px;
    padding: 10px;
    color : ${({ isGood }) => isGood ? 'black' : 'white'};
    border-radius: 10px;
    border : none;
    background-color: ${({ isGood }) => isGood ? '#02ff79' : '#1e1e1e'};
    box-shadow: ${({ isGood }) => isGood ? "0 0 10px #02ff79" : '0px 2px 4px rgba(0, 0, 0, 0.25)'};
    transform:${({ isGood }) => isGood ? 'scale(1.01)' : 'scale(1)'};
  `;
  
  
  
  const CardImage = styled.img`
    width: 20%;
    height: 20%;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px; 
    border-bottom-left-radius: 15px; 
    border-bottom-right-radius: 15px; 
    @media (max-width: 820px) {
      border-top-left-radius: 10px; 
      border-top-right-radius: 10px; 
      border-bottom-left-radius: 10px; 
      border-bottom-right-radius: 10px; 
      width: 30%;
      height: 30%;
         }
         @media (max-width: 325px) {
          width: 25%;
          height: 25%;
               } 
               @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
                width: 22%;
              height: 22%;
                     }                 
  `;
  

  
  const CardTitle = styled.h3`
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @media (max-width: 820px) {
      font-size: 1rem;
         }
  `;
  
  const CardNumber = styled.h3`
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    @media (max-width: 820px) {
      font-size: 1rem;
         }
  `;

  const TextImg = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    width:40%;
    justify-content:space-between;
    @media (max-width: 820px) {
      width: 55%;
         }
         @media (max-width: 325px) {
          width: 65%;
               }      
    
  `;
  const ContainerCardText = styled.div`
  width: 20%;
  height: 6vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 820px) {
    width: 25%;
       }
       @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
        width: 15%;
             }     
  `;
  
  const CardText = styled.div`
    width: 100%;
    height: 7vh;
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px; 
    border-bottom-left-radius: 10px; 
    border-bottom-right-radius: 10px; 
    background-color: #000;
    font-size:2rem;
    font-weight:bold;
    text-shadow:0 0 10px #02ff79;
    color:transparent;
    color: #02ff79;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 820px) {
      font-size: 1rem;
         }

  `;
  
  const CardPoint = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  margin-top:-2%;
  @media (max-width: 820px) {
    font-size: 0.6rem;
       }
  
`;
 
const ScrollableContainer = styled.div`
  height: 59vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
  ::-webkit-scrollbar {
    width: 4px;

  }

  ::-webkit-scrollbar-track {
    background-color: #1e1e1e;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;

  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: #999;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  @media (max-width: 325px) {
    height: 70vh;
    padding-right: 15px;
   
       } 
  
`;
 const ContainerFoot = styled.div`
 margin-top:5%;
 width:100%;
 `


const RankingOff = () => {

  return (

    <CardsContainer>
        <CardsAll>
            <ScrollableContainer>
    {cardsData.map((card, index) => (
        
      <Card key={index} isGood={card.isGood}>
        <TextImg> 
          <CardImage src={card.imageSrc} />
            <CardNumber>{card.num} </CardNumber>
          <CardTitle> {card.id}</CardTitle>
        </TextImg>
        <ContainerCardText>
      <CardText>{card.points} <CardPoint>
        PTS
      </CardPoint>
      </CardText>
        </ContainerCardText>
      </Card>
      
    ))}
    </ScrollableContainer>
    </CardsAll>
    <ContainerFoot>
    <FooterNotHome/>
    </ContainerFoot>
  </CardsContainer>
);
};



export default RankingOff