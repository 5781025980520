import React, { useState } from "react";
import styled from "styled-components";
import Vector2 from "../Assets/Vector2.png"
import OpenIcon from "../Assets/Ouvert.png";
import CloseIcon from "../Assets/Fermer.png";

interface FaqData {
  question: string;
  title?: string;
  answer: string;
}

const faqData: FaqData[] = [
  {
    title: "#1",
    question: "ERAT ULTRICIES ET EGET CONDIMENTUM DIAM NON FAUCIBUS NULLA SENECTUS?",
    answer: "condimentum nullam a nibh sit non. Sit nunc elementum vitae ac tortor ornare et. Eu eu dolor lacinia maecenas maecena Nulla in condimentum nullam a nibh sit non. Sit nunc elementum vitae ac tortor ornare et. Eu eu dolor Ivitae. Sit est integer viverra vehicula. Ipsum facilisis neque pellentesque ullamcorper."
  },
  {
    title: "#2",
    question: "ERAT ULTRICIES ET EGET CONDIMENTUM DIAM NON FAUCIBUS NULLA SENECTUS?",
    answer: "condimentum nullam a nibh sit non. Sit nunc elementum vitae ac tortor ornare et. Eu eu dolor lacinia maecenas maecena Nulla in condimentum nullam a nibh sit non. Sit nunc elementum vitae ac tortor ornare et. Eu eu dolor Ivitae. Sit est integer viverra vehicula. Ipsum facilisis neque pellentesque ullamcorper."
  },
  {
    title: "#3",
    question: "ERAT ULTRICIES ET EGET CONDIMENTUM DIAM NON FAUCIBUS NULLA SENECTUS?",
    answer: "condimentum nullam a nibh sit non. Sit nunc elementum vitae ac tortor ornare et. Eu eu dolor lacinia maecenas maecena Nulla in condimentum nullam a nibh sit non. Sit nunc elementum vitae ac tortor ornare et. Eu eu dolor Ivitae. Sit est integer viverra vehicula. Ipsum facilisis neque pellentesque ullamcorper.."
  },
  {
    title: "#4",
    question: "ERAT ULTRICIES ET EGET CONDIMENTUM DIAM NON FAUCIBUS NULLA SENECTUS?",
    answer: "condimentum nullam a nibh sit non. Sit nunc elementum vitae ac tortor ornare et. Eu eu dolor lacinia maecenas maecena Nulla in condimentum nullam a nibh sit non. Sit nunc elementum vitae ac tortor ornare et. Eu eu dolor Ivitae. Sit est integer viverra vehicula. Ipsum facilisis neque pellentesque ullamcorper."
  },
  {
    title: "#5",
    question: "ERAT ULTRICIES ET EGET CONDIMENTUM DIAM NON FAUCIBUS NULLA SENECTUS??",
    answer: "condimentum nullam a nibh sit non. Sit nunc elementum vitae ac tortor ornare et. Eu eu dolor lacinia maecenas maecena Nulla in condimentum nullam a nibh sit non. Sit nunc elementum vitae ac tortor ornare et. Eu eu dolor Ivitae. Sit est integer viverra vehicula. Ipsum facilisis neque pellentesque ullamcorper."
  }
];

const FaqContainer = styled.div`
 
    display: flex;
    flex-direction: column;
    margin-top:5%;
    align-items: center;
    width:100%;
    height:100%;
  
   
  
`;

const FaqQuestion = styled.div`
    background-color: #1e1e1e;
    border: none;
    border-radius:10px;
    padding: 35px 25px 25px 25px;
    width: 65vw;
    text-align: left;
    font-weight: bold;
    margin-top:0.5%;
    cursor: pointer;
    @media (max-width: 820px) {
      padding: 25px 5px 10px 5px;
      width: 98vw;
      border-radius:0px;
      margin-top:1%;
   
 
     }
     @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
      padding: 40px 5px 10px 5px;
                        } 
  
`;

const FaqAnswer = styled.div<{ isVisible: boolean }>`
    height: ${props => props.isVisible ? "7vh" : "0"};
    margin-top: ${props => props.isVisible ? "0%" : "0"};
    overflow: hidden;
    font-weight:100;
    width: 90%;
    font-size:0.86rem;
    color:white;
    background-color: #1e1e1e;
    transition: all 0.5s ease-in-out;
    @media (max-width: 820px) {
      font-size:0.9rem;
      height: ${props => props.isVisible ? "26vh" : "0"};
      margin-top: ${props => props.isVisible ? "2%" : "0"};
     }
     @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
      height: ${props => props.isVisible ? "13vh" : "0"};
                        } 
`;

const Answer = styled.div`
    margin-left:9%;
    @media (max-width: 820px) {
      margin-left:10%;
     
     }
`

 const ButtonFaq = styled.button`
    display:flex;
    justify-content:flex-end;
    background-color:transparent;
    border:none;
    color:#02ff79;
    width:100%;
    font-size:2rem;
    align-items:center;
    @media (max-width: 820px) {
      font-size:1.5rem;
     margin-top:-0.1%;
   
     }
    
 `

const TitreAndQuestion = styled.div`
    font-size:1rem;
    color:white;
    flex-direction:row;
    display:flex;
    align-items:center;
    margin-top:-3%;
    @media (max-width: 820px) {
      font-size:1rem;
      margin-top:-5%;
      margin-bottom:3%;
      width:80%;
      padding-left:10%; 
      flex-direction:column;
      align-items:start;
      
     }
    
    
`
const NumberTitle = styled.div<{ isVisible: boolean }>`
  font-size:2rem;
  -webkit-text-fill-color: ${(props) => (props.isVisible ? "white" : "transparent")};
    margin-right:5%;
    -webkit-text-stroke: 1px;
    text-transform: uppercase;
    transition: -webkit-text-stroke 0.3s ease-out;
    @media (max-width: 820px) {
      font-size:1.6rem;
      padding-bottom:4%;
     
     }

`
const TextContainer = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items: start;
    flex-direction:column;
    width:68%;
    @media (max-width: 820px) {
      width:80%;

     }

`

const TitleFaq = styled.h1`
    text-shadow:0.1px 0.1px 0.1px #fff, 0.1px 0.1px 5px #ccc;
    font-size:3rem;
    display:flex;
    color:white;
    @media (max-width: 820px) {
      font-size:2rem;
    }
   
    
`
const SubtitleFaq = styled.h4`
    text-shadow:0.1px 0.1px 0.1px #fff, 0.1px 0.1px 5px #ccc;
    font-size:1.2rem;
    display:flex;
    color:white;
    margin-top:-3%;
    @media (max-width: 820px) {
      margin-top:-6%;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
      margin-top:-3%;
                        }  
`

const BackImg = styled.div`
   width:100%;
   height:10vh;
     background-image: url(${Vector2});
     background-size: cover;
     z-index:10;
     background-repeat: no-repeat;
`
const FaqButtonIcon = styled.img`
   width:2%;
   height:2%;
   @media (max-width: 820px) {
    width:5%;
    height:5%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    width:4%;
    height:4%;
                      } 
`;

const FoireAQuestion = () => {
  const [activeQuestion, setActiveQuestion] = useState<number | null>(null);

const [selected, setSelected] = useState(false);

  const handleTitleClick = () => {
    setSelected(!selected);

  };
  
  return (
    <FaqContainer>
  
        <TextContainer>
          <TitleFaq>FAQ</TitleFaq>
          <SubtitleFaq>FREQUENTLY ASK QUESTION</SubtitleFaq>
        </TextContainer>
      
          {faqData.map((faq, index) => (
            <div key={index}>
              <FaqQuestion >  
                  <ButtonFaq onClick={() => setActiveQuestion(activeQuestion === index ? null : index)}>
                    <FaqButtonIcon src={activeQuestion === index ? CloseIcon : OpenIcon} alt="Toggle" />
                  </ButtonFaq>
                  <TitreAndQuestion>
                    <NumberTitle isVisible={activeQuestion === index}>
                      {faq.title} 
                    </NumberTitle>
                    {faq.question}
                  </TitreAndQuestion> 
                  <Answer>
                    <FaqAnswer isVisible={activeQuestion === index}>
                      {faq.answer}
                    </FaqAnswer>
                  </Answer>
              </FaqQuestion> 
              
           </div> 
          
      ))}
    
    </FaqContainer>
  );
};

export default FoireAQuestion