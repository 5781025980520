import React from "react";
import styled from "styled-components";
import Lock from "../Assets/LockIcon.png"
import Unlocked from "../Assets/Unlocked.png"
import Locked from "../Assets/Locked.png"
import CardBackground1 from "../Assets/Card0.png";
import CardBackground2 from "../Assets/Card1.png";
import CardBackground3 from "../Assets/Card2.png";
import CardBackground4 from "../Assets/Card3.png";
import CardBackground5 from "../Assets/Card4.png";

interface CardData  {
    chapter: string;
    title: string;
    date: string;
    lock: boolean;
    backgroundImage: string; 
};

interface CardProps {
    data: CardData;
};


const CardContainer = styled.div<{ backgroundImage: string }>`
background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
    display:flex;
    flex-direction:column;
    background-color: #1e1e1e;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    background-size:cover;
    height:65vh;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px;
    @media (max-width: 820px) {
      width: 100%;
     
      height:55vh;
     
    
   }
   @media (max-width: 325px) {
    height:80vh;
       }  
       @media screen and (max-width: 900px) and (max-height: 1500px) {

       } 
       @media only screen and (device-width: 768px) and (device-height: 1024px)  {
          height: 600px;
        }

   
`;
const ImgLock = styled.img`
   width:25%;
   height:40%;
   padding:10px;

`

const ChapterAndTitle = styled.div`
    display:flex;
    justify-content:flex-start;
    flex-direction:column;
    margin-top:-3%;
    
`
const CardChapter = styled.h4`
    color:white;
    font-size:1rem;
    font-weight: bold;
    text-shadow:  rgba(255,255,255,0.9) 0px 0px 2px; 
    //text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
`;

const CardTitle = styled.h1`
    color:white;
    font-size: 2.2rem;
    font-weight: bold;
    margin-top:-3%;
    line-height:1em;
    text-shadow:  rgba(255,255,255,0.9) 0px 0px 2px; 
    //text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
    @media (max-width: 820px) {
    
      font-size: 1.7rem;
   }
    `;


const DateAndLock = styled.div `
    display:flex;
    justify-content:space-between;
    flex-direction:row;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
     
             }     

`
const CardDate = styled.p`
    font-size:0.8rem;
    color:white;
  //text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
`;

const CardLock = styled.div`
    font-size:0.8rem;
    display:flex;
    flex-direction:row;
    align-items:center;
  
    justify-content:center;
    color:white;
    //text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
`;
const LineContainer = styled.div`
    display:flex;
    flex-direction:column;

`
const Line = styled.div`
    margin-top:10%;
    border-top: 0.2px solid white;
    margin-left: -5%;
    width:110%;
    opacity:0.4;
    @media only screen and (device-width: 768px) and (device-height: 1024px)  {
      margin-top:0%;
      width:90%;
    } 
    
`


export const Card = ({ data }: CardProps) => {
  const { chapter, title, date, lock } = data;

  return (
    <CardContainer  backgroundImage={data.backgroundImage}>
       <ChapterAndTitle>
            <CardChapter>{chapter}</CardChapter>
            <CardTitle>{title}</CardTitle>
        </ChapterAndTitle>  
        <LineContainer>
            <Line/>
        <DateAndLock>
            <CardDate>{date}</CardDate>
            <CardLock> 
            <ImgLock src={!lock ? Locked : Unlocked} /> 
            {!lock ? 'LOCKED' : 'UNLOCKED'}
            </CardLock>
        </DateAndLock>
         </LineContainer>
    </CardContainer>
  );
};

const cardData: CardData[] = [
  {
    chapter: "CHAPTER 0" ,
    title: "GATEWAY TO THE FUTUR",
    date: "JUNE 7",
    lock: true,
    backgroundImage: CardBackground1, 
  },
  {
    chapter: "CHAPTER 1" ,
    title: "ITS ALL ABOUT QUESTING",
    date: "JUNE 14",
    lock: false,
    backgroundImage: CardBackground2, 
  },
  {
    chapter: "CHAPTER 2" ,
    title: "THE LEGEND OF HENRI DE GAYETTE",
    date: "JULY 24",
    lock: false,
    backgroundImage: CardBackground3, 
  },
 
  {
    chapter: "CHAPTER 3" ,
    title: "LETS DIVE TO THE CROCOVERSE",
    date: "SEPTEMBER 11",
    lock: false,
    backgroundImage: CardBackground4, 
  },
  {
    chapter: "CHAPTER 4" ,
    title: "FROM THE EMERGE TO...",
    date: "OCTOBER 31",
    lock:false,
    backgroundImage: CardBackground5, 
  },
 
];

const CardList = () => {
  return (
    <>
      {cardData.map((card) => (
        <Card key={card.title} data={card} />
      ))}
    </>
  );
};

export default CardList;