import React, { useState } from 'react';
import styled, {keyframes} from 'styled-components';
import Icon from "../Assets/VertOk.png"
import PopUpDiscord from "./PopUpDiscord"
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GenNFTService from '../services/fetchGenesis';
import tokenIdsService from '../services/tokenIds.service';

interface SquareContainerProps {
  tokenId: string;
  imageUrl: string;
}

const scaleUp = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
`;

//   const cardData: SquareContainerProps[] = [
//     {
//         images: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//         title : "1345678",
//         icon: Icon,
//     },
//     {
//         images: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//         title : "1345678",
//         icon: Icon,
//     },
//     {
//         images: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//         title : "1345678",
//         icon: Icon,
//     },
//     {
//         images: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//         title : "1345678",
//         icon: Icon,
//     },
//     {
//         images: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//         title : "1345678",
//         icon: Icon,
//     },
//     {
//         images: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//         title : "1345678",
//         icon: Icon,
//     },

//      {
//         images: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//         title : "1345678",
//         icon: Icon,
//     },
//     {
//         images: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//         title : "1345678",
//         icon: Icon,
//     },
// ];

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 100;

`;

const PopupContainer = styled.div`
    position: fixed;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px; 
    border-bottom-left-radius: 15px; 
    border-bottom-right-radius: 15px; 
    top: 50%;
    left: 50%;
    width:45%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding-bottom: 1rem;
    padding-top: 1rem;
    z-index: 101;
    animation: ${scaleUp} 0.3s ease-out forwards;
    @media (max-width: 800px) {
      width:90%;
    }
`;

const ImageContainer = styled.div`
    margin-top:-3%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    transform:scale(0.9);
    overflow: scroll;
    height: 40vh;
    padding-right:5%;
    @media (max-width: 800px) {
      height: 47vh;
      grid-template-columns: repeat(2, 1fr);
    }
   
`;

const Image = styled.div<{ selected: boolean }>`
    position:relative;
    margin: 0.5rem;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px; 
    border-bottom-left-radius: 15px; 
    border-bottom-right-radius: 15px; 
    box-shadow:${({ selected }) => (selected ? " inset 0 0 10px #02ff79" : "0")};
    border:${({ selected }) => (selected ? " 0.1px solid #02ff79" : "none")};
    padding-top: 0.2rem; 
    padding-left: 0.2rem; 
    padding-right: 0.2rem; 
    @media (max-width: 800px) {
      width:95%;
    }
`;

const ImageWrapper = styled.div`
    margin: 0.5rem;
    position: relative;
`;

const ImageTitle = styled.p`
    margin-top: 0.5rem;
    text-align: center;
    font-size: 0.8rem;
`;


const Button = styled.button<{disabled: boolean}>`
    background-color: ${({ disabled }) => (disabled ? "#ccc" : "#02ff79")};
    color: black;
    font-weight:bold;
    width:40%;
    padding:  15px 20px ;
    border: none;
    border-radius:20px;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    @media (max-width: 800px) {
      width:70%;
  }
`;
const ImageToken = styled.img`
    width: 100%;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px; 
    border-bottom-left-radius: 15px; 
    border-bottom-right-radius: 15px; 
`;

const Title = styled.h1`
    color:black;
    font-size:1.2rem;
    align-items:center;
    justify-content:center;
    text-align:center;
    display:flex;
    width:65%;
    @media (max-width: 800px) {
      width:75%;
      font-size:1rem;
    }

`
const IconV = styled.img`
    position: absolute;
    top: 0;
    width:20%;
    height:20%;
    right: 0;
    padding:2px;
   
`;
const CloseButton = styled.button`
    position: absolute;
    top: 26.5%;
    right: 35.5%;
    border: 0.5px solid grey;
    background-color: transparent;
    border-radius:50%;
    cursor: pointer;
    color:grey;
    z-index:9999;
    @media (max-width: 800px) {
      top: 19%;
      right: 10%;
      transform:scale(1.8);
    }
  `;

const PopUp = () => {
    const [selectedImage, setSelectedImage] = useState(-1);
    // const [isVisible, setIsVisible] = useState(true);
    // const [showSecondPopup, setShowSecondPopup] = useState(false);
    const [ownerNft, setOwnerNft] = useState<SquareContainerProps[]>([])
    const [choosenTokenId, setChoosenTokenId] = useState<string>("")
    const {user} = useSelector((state:any) => state.auth)
    const [isLoading, setIsLoading] =  useState(false)
    const [multiHolderSuccess, setMultiHolderSuccess ] = useState<boolean>(false)
    const navigate = useNavigate()


    const handleFetchNft = async() =>{
      setIsLoading(true)
      const response = await GenNFTService.getGenesisNFTs(user.owner.wallet, user.owner._id)
      if(!response) return;
      setOwnerNft(response.metadata)
      setIsLoading(false)
    }
    
    const handleImageClick = (index: number) => {
      setSelectedImage(index);
      setChoosenTokenId(ownerNft[index].tokenId)
    };

    const handleSetTokenId = async() => {
      const response = await tokenIdsService.setChoosenTokenId(user.owner.wallet, user.owner._id, choosenTokenId!)
      if(!response) return;
      setMultiHolderSuccess(true)
    }

    useEffect(() =>{
      if(multiHolderSuccess){
        if(!user.owner.discord_userId ){
          navigate("/discord")
        }
        else{
          navigate("/collection")
        }
      }

    },[multiHolderSuccess])

    useEffect(()=>{
        handleFetchNft()
    },[])


  // const handleButtonClick = () => {
  //   setIsVisible(false);
  //   setShowSecondPopup(true);
  // };
  // const [isVisible2, setIsVisible2] = useState(true);

  // const handleCloseClick = () => {
  //   setIsVisible2(false);
  // };
    
  
  return (
    <>
     <Overlay>
        <PopupContainer>
          <Title>You are a multiple holder, please select the genesis you want to upgrade</Title>
          {isLoading && <Title style={{fontSize:'0.7rem'}}>Getting your NFTs...</Title>}
          <ImageContainer>
            {!isLoading && ownerNft!.map((elem, index) => (
              <div key={index}>
                <Image
                  selected={index === selectedImage}
                  onClick={() => handleImageClick(index)}
                >
                  <ImageToken src={elem.imageUrl} />
                  {index === selectedImage && <IconV src={Icon} />}
                </Image>
                <ImageTitle>Token ID : {elem.tokenId}</ImageTitle>
              </div>
            ))}
          </ImageContainer>
          <Button disabled={choosenTokenId.length === 0} onClick={handleSetTokenId}>Select this genesis</Button>
        </PopupContainer>
      </Overlay>  
        {/* {showSecondPopup && ( 
            <>
            {isVisible2 && (
        <Overlay>
          <CloseButton onClick={handleCloseClick}>X</CloseButton>
            <PopUpDiscord/>
       </Overlay>
        )}
       </>
      )} */}
    </>
  );
};

export default PopUp;
