import React, { useState } from 'react';
import styled from 'styled-components';
import Rond1 from '../Assets/Rond1.png';
import Rond2 from '../Assets/Rond2.png';
import Rond3 from '../Assets/Rond3.png';
import Rond4 from '../Assets/RondGEtoile.png';
import Rond5 from '../Assets/Rond3Etoile.png';
import Rond6 from '../Assets/Rond2Etoile.png';
import Rond7 from '../Assets/RondPEtoile.png';
import Rond8 from '../Assets/RondPasEtoile.png';
import Voutcher from '../Assets/Voutcher.png';
import FooterNotHome from '../Footer/FooterNotHome';
import Fermer from "../Assets/Fermer.png"
import Ouvert from "../Assets/Ouvert.png"

interface Data {
  image: string;
  classement: string;
  titre: string;
  subtitle:string;
  answer: string[];
  imgAns: string;
}

const CardData: Data[] = [
  {
    image: Rond1,
    classement: 'TOP 1',
    titre: 'French vip tour',
    subtitle:"A trip to Paris for two to live a unique french Crocodiles experience",
    answer:[
        "• Flight tickets to Paris for 2",
        "• VIP Rolland Garros Expérience for 2",
        "• A gastronomic experience in a starred restaurant",
        "• The hotel for 3 nights",
        "• Visit of the Lacoste factory",
        "• Shopping Experience at La Lacoste Arena",
      ],
    imgAns: Voutcher,
  },
  {
    image: Rond2,
    classement: 'TOP 2',
    titre: 'Design your own Lacoste collection',
    subtitle:"Become the brand's new designer by collaborating with our teams to create your own collection.",
    answer: [
        "• Flight tickets to Paris for 2",
        "• VIP Rolland Garros Expérience for 2",
        "• A gastronomic experience in a starred restaurant",
        "• The hotel for 3 nights",
        "• Visit of the Lacoste factory",
        "• Shopping Experience at La Lacoste Arena",
      ],
    imgAns: Voutcher,
  },
  {
    image: Rond3,
    classement: 'TOP 3',
    titre: 'Face of Lacoste for a global campaign',
    subtitle:"Become the new face of the brand for a global campaign",
    answer: [
        "Flight tickets to Paris for 2",
        "VIP Rolland Garros Expérience for 2",
        "A gastronomic experience in a starred restaurant",
        "The hotel for 3 nights",
        "Visit of the Lacoste factory",
        "Shopping Experience at La Lacoste Arena",
      ],
    imgAns: Voutcher,
  },
  {
    image: Rond4,
    classement: 'TOP 4 TO 6',
    titre: 'Personal shopping experience (worldwide)',
    subtitle:"Enjoy a personalized shopping experience in one of our boutiques",
    answer: [
        "Flight tickets to Paris for 2",
        "VIP Rolland Garros Expérience for 2",
        "A gastronomic experience in a starred restaurant",
        "The hotel for 3 nights",
        "Visit of the Lacoste factory",
        "Shopping Experience at La Lacoste Arena",
      ],
    imgAns: Voutcher,
  },
  {
    image: Rond5,
    classement: 'TOP 7 TO 10',
    titre: 'Personal shopping experience (worldwide)',
    subtitle:"Enjoy a personalized shopping experience in one of our boutiques",
    answer: [
        "Flight tickets to Paris for 2",
        "VIP Rolland Garros Expérience for 2",
        "A gastronomic experience in a starred restaurant",
        "The hotel for 3 nights",
        "Visit of the Lacoste factory",
        "Shopping Experience at La Lacoste Arena",
      ],
    imgAns: Voutcher,
  },
  {
    image: Rond6,
    classement: 'TOP 11 TO 50',
    titre: '200€ on Lacoste.com',
    subtitle:"",
    answer: [
        "Flight tickets to Paris for 2",
        "VIP Rolland Garros Expérience for 2",
        "A gastronomic experience in a starred restaurant",
        "The hotel for 3 nights",
        "Visit of the Lacoste factory",
        "Shopping Experience at La Lacoste Arena",
      ],
    imgAns: Voutcher,
  },
  {
    image: Rond7,
    classement: 'TOP 51 TO 100',
    titre: '150€ on Lacoste.com',
    subtitle:"",
    answer: [
        "Flight tickets to Paris for 2",
        "VIP Rolland Garros Expérience for 2",
        "A gastronomic experience in a starred restaurant",
        "The hotel for 3 nights",
        "Visit of the Lacoste factory",
        "Shopping Experience at La Lacoste Arena",
      ],
    imgAns: Voutcher,
  },
  {
    image: Rond8,
    classement: 'TOP 101 TO 200',
    titre: '100€ on Lacoste.com',
    subtitle:"",
    answer: [
        "Flight tickets to Paris for 2",
        "VIP Rolland Garros Expérience for 2",
        "A gastronomic experience in a starred restaurant",
        "The hotel for 3 nights",
        "Visit of the Lacoste factory",
        "Shopping Experience at La Lacoste Arena",
      ],
    imgAns: Voutcher,
  },
];

const Container = styled.div`
  width: 100%;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-bottom:10%;
`;

const ConainerCard = styled.div`
  background-color: #1e1e1e;
  border: none;
  border-radius: 15px;
  display: flex;
  padding:10px;
  flex-direction: column;
  justify-content: center;
  width: 60vw;
  font-weight: bold;
  margin-top: 1%;
  cursor: pointer;

  @media (max-width: 820px) {
    align-items:center;
    width: 95vw;
    border-radius: 10px;
    padding:0px;
    margin-top: 3%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    width: 85vw;
    padding-top:10px;
    align-items:center;
   
                 } 
`;

const AllInCard = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  @media (max-width: 820px) {
justify-content:space-between;
width:100%;

    
  }
`;

const ContainerImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 7%;
  width: 30%;
  height: 100%;
  @media (max-width: 820px) {
    width: 70px;
    height: 70px;
    padding-left: 0;
    justify-content: center;
    
}
@media (max-width: 325px) {
  width: 50px;
  height: 50px;
  padding-left: 0;
  justify-content: center;
  
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
  width: 70px;
    height: 70px;
 
               } 

`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
  transform: scale(0.92);

`;

const TextAndTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: -2%;
  width: 70%;
  padding-left: 6%;


`;

const Top = styled.h1`
  display: flex;
  justify-content: flex-start;
  text-align: start;
  width: 35%;
  font-size: 1.1rem;
  color: white;
  @media (max-width: 820px) {
    font-size: 0.9rem;
      width: 100%;
      justify-content: center;
      text-align: center;
    
}

`;

const ContainerButton = styled.div`
  display: flex;
  width: 15%;
  justify-content: flex-end;
`;

const ButtonClickIcon = styled.button`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border: none;
  color: #02ff79;
  text-align: center;
  font-size: 1.2rem;
  align-items: center;


`;

const TitleExplication = styled.h4`
  font-size: 1rem;
  color: white;
  justify-content: flex-start;
  text-transform: uppercase;
  font-weight: bold;
  align-items: center;
  text-align: start;
  display: flex;
  width: 90%;
  padding-left: 8%;
`;

const TitleExplication2 = styled.h4`
  font-size: 0.8rem;
  color: white;
  margin-top:-3%;
  justify-content: flex-start;
  font-weight:normal;
  align-items: center;
  text-align: start;
  display: flex;
  width: 90%;
  padding-left: 8%;
  @media (max-width: 820px) {
   width:100%;
   font-size:0.7rem;

  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    font-size:0.9rem;
   
                 } 
`;
const Open = styled.div`
  width: 100%;
  margin-left: 10%;
`;

const OpenInside = styled.div<{ isVisible: boolean }>`
  height: ${props => (props.isVisible ? '12vh' : '0')};
  padding: ${props => (props.isVisible ? '2%' : '0')};
  overflow: hidden;
  font-weight: 100;
  display: flex;
  flex-direction: row;
  width: 80%;
  color: white;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.9rem;
  background-color: #1e1e1e;
  transition: all 1s ease-in-out;

  @media (max-width: 820px) {
    font-size: 1rem;
    height: ${props => (props.isVisible ? '35vh' : '0')};
    margin-top: ${props => (props.isVisible ? '2%' : '0')};
    flex-direction: column;
  }
  @media (max-width: 325px) {
    height: ${props => (props.isVisible ? '65vh' : '0')};
  }
  @media (min-width: 350px) and (max-width: 375px){
    height: ${props => (props.isVisible ? '45vh' : '0')};
  }
  @media (device-width: 414px) and (device-height: 736px) {
    height: ${props => (props.isVisible ? '45vh' : '0')};
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    height: ${props => (props.isVisible ? '35vh' : '0')};
   
                 } 
@media only screen and (min-width: 1025px) and (max-width: 1366px) {
  height: ${props => (props.isVisible ? '15vh' : '0')};
                 }                
`;
const VerticalLine = styled.div<{ isVisible: boolean }>`
  border-left: 0.5px solid white;
  opacity: 0.5;
  align-items: center;
  margin-top: 2%;
  margin-left: 3%;
  justify-content: center;
  display: flex;
  height: 80%;
  transition: height 0.5s ease-in-out;

  ${(props: { isVisible: boolean }) =>
    props.isVisible &&
    `
    height: 90%;
  `}

  @media (max-width: 820px) {
    margin-top: 0;
  }
`;



const ReadMore = styled.p`
  color: white;
  font-size: 0.6rem;
  @media (max-width: 820px) {
    display:none;
  }
`;

const VoutcherIcon = styled.img`
  width: 20%;
  height: 90%;
 margin-top:-5%;
 transform:scale(0.8);
 @media (max-width: 820px) {
    width:60%;
    height: 40%;
    margin-left:3%;
    margin-top:0%;
 
   }
   @media (max-width: 325px) {
    margin-left:10%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    width: 45%;
    height: 40%;
    margin-left:-10%;
  
  
   
                 } 
`;

const AnswerExplication = styled.p`
  color: white;
  font-size: 0.7rem;
  width: 40%;
  line-height: 0.8em;
  padding-left: 20px;
  @media (max-width: 820px) {
    width:80%;
    line-height:1.2em;
    margin-left:30%;
 
 
   }
   @media (max-width: 325px) {
    margin-left:25%;
    width:70%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    font-size:0.9rem;
   
                 } 
`;

const ContainerFoot = styled.div`
  margin-top: -10%;
  width: 100%;
`;
const ContainerTT = styled.div`
display:flex;
flex-direction:column;
width:100%;
`;

const ContainerTopImg = styled.div`
display:flex;
width:40%;
gap:20px;
align-items:center;
@media (max-width: 820px) {
margin-left:5%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
width:50px;
gap:0;
}
`;


const RewardList = () => {
  const [activeMission, setActiveMission] = useState<number | null>(null);

  return (
    <>
      <Container>
        {CardData.map((Card, index) => (
          <div key={index}>
            <ConainerCard>
              <AllInCard>
                <ContainerTopImg>
                <ContainerImage>
                  <Icon src={Card.image} />
                </ContainerImage>
                  <Top>{Card.classement}</Top>
                  </ContainerTopImg>
                  <TextAndTop>
                  <VerticalLine isVisible={activeMission === index} />
                  <ContainerTT>
                  <TitleExplication>{Card.titre}</TitleExplication>
                  <TitleExplication2>{Card.subtitle}</TitleExplication2>
                  </ContainerTT>
                </TextAndTop>
                <ContainerButton>
                  <ButtonClickIcon onClick={() => setActiveMission(activeMission === index ? null : index)}>
                    <ReadMore>{activeMission === index ? 'Close' : 'More Info'}</ReadMore>
                    &nbsp; <img src={activeMission === index ? Ouvert : Fermer } alt="" />
                  </ButtonClickIcon>
                </ContainerButton>
              </AllInCard>
              <Open>
                <OpenInside isVisible={activeMission === index}>
                  <VoutcherIcon src={Card.imgAns} />
                  <AnswerExplication>

                    {Card.answer.map((line, lineIndex) => (
                      <p key={lineIndex}>{line}</p>
                    ))}
                  </AnswerExplication>
                </OpenInside>
              </Open>
            </ConainerCard>
          </div>
        ))}
      </Container>
      <ContainerFoot>
        <FooterNotHome />
      </ContainerFoot>
    </>
  );
};

export default RewardList;
