import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import LogoL from '../Assets/LogoReal.svg'
import Vector from "../Assets/Vector.png"
import Discord from "../Assets/DicordNav.png"
import DiscordMobile from '../Assets/DiscordMobile.svg'
import Coin from "../Assets/Coin.png"
import Vec from "../Assets/vec.png"
import Icon from "../Assets/Mobile.png"
import Vector2 from "../Assets/Vector2.png"
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import getWalletLogo from '../helper/getConnectorImage';
import { useAccount } from 'wagmi';

const NavContainer = styled.div`
    width:100%;
    height:15%;
    display:flex;
    background-image: url(${Vector});
    background-size: 50%;
    background-repeat: repeat;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    @media (max-width: 820px) {
        background-size: cover;
        background-repeat: no-repeat;
        margin-top:-7%;
        background-image: url(${Vector2});
      }
      @media (max-width: 325px) {
        margin-top:-2%;
      }
`
const NavGauche = styled.div`
    width:100%;
    height:15%;
    display:flex;
    padding-left:7%;
    align-items:start;
    justify-content:flex-start;
    @media (max-width: 820px) {
        padding-left:3%;
        
    }

`
const NavDroite= styled.div`
    width:100%;
    height:15%;
    display:flex;
    padding-right:7%;
    align-items:center;
    justify-content:flex-end;
    @media (max-width: 820px) {
        padding-right:3%;
 
    }

`

const Logo = styled.img `
    width:10%;
    height: 10%;
    transform:scale(1.3);
    @media (max-width: 820px) {
     display:none;
    }
`
const CollecButton = styled.button`
    height:7%;
    border-radius: 20px;
    padding: 12px 20px;
    background-color:transparent;
    border: 1px solid #02ff79;
    color: white;
    font-weight:600;
    text-align: center;
    margin-left:10%;
    font-size:0.6rem;
    cursor:pointer;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    &:hover{
      box-shadow: 0 0 10px #02ff79; 
    }
    @media (max-width: 820px) {
        margin-left:0%;
        height:6%;
        padding: 12px 15px;
    }
    
`
const DiscordButton = styled.button`
    border-radius: 20px;
    width:36%;
    font-size:0.7rem;
    padding: 12px ;
    background-color:#1e1e1e;
    border:none;
    color: white;
    text-align: center;
    align-items:center;
    justify-content:center;
    flex-direction:row;
    display:flex;
    margin-right:2%;
    cursor:pointer;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    &:hover{
        background-color:#3B3B3B; 
    }
    .icon {
        margin-right: 10px;
      }
    
      .text {
        font-size:0.7rem;
      }
      @media (max-width: 820px) {
        display:none;
    }
`
const WalletProfilButton = styled.button`
    align-items:center;
    justify-content:center;
    flex-direction:row;
    display:flex;
    border-radius: 20px;
    padding: 12px ;
    background-color:#1e1e1e;
    width:36%;
    font-size:0.7rem;
    border: none;
    color: white;
 
    text-align: center;
    cursor:pointer;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    &:hover{
        background-color:#3B3B3B; 
    }
    .icon {
        margin-right: 10px;
      }
      .text {
        font-size:0.7rem;
      }
      @media (max-width: 820px) {
        display:none;
    }
    
`
const ContainerText = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    width:100%;
    transform:scale(1.8);
    height:15vh;
    z-index:0;

    @media (max-width: 820px) {
        transform:scale(1);
    
        height:20vh;
    }
    
    
`
const TextGenesis = styled.h1`
    text-align:center;
    align-items:center;
    justify-content:center;
    display:flex;
    width:100%;
    color:white;
    font-size:1.5rem;
    text-shadow:  rgba(255,255,255,0.9) 0px 0px 6px; 
    @media (max-width: 820px) {
        font-size:1rem;
    }
    @media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px)  {
    margin-top:10%;
    font-size:1.7rem;
}
`
const TextEvolution = styled.h3`
    text-align:center;
    align-items:center;
    display:flex;
    justify-content:center;
    color:white;
    margin-top:-5.5%;
    font-size:0.7rem;
    text-shadow:  rgba(255,255,255,0.9) 0px 0px 6px; 
    @media (max-width: 820px) {
        font-size:0.5rem;
        margin-top:-7.5%;
    }
    @media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px)  {
    font-size:1rem;
}
`
const DiscordNavbar = styled.img `
    width:15%;
    height: 15%;
`
const CoinNavbar = styled.img `
    width:10%;
    padding-right: 2px;
    height: 10%;
    transform:scale(1.7);

`

const MobileButton = styled.button<{ isOpen: boolean }>` 
  border: none;
  display: none; 
  background-color: transparent;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 820px) {
    display: flex; 
    background-image: url(${Icon});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;


const MenuContainer = styled.div<{ isOpen: boolean }>`
  display: none; /* Hide on desktop screens */
  @media (max-width: 820px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 1);
    background-image:url(${Vec});
    background-size:contain;
    background-repeat: no-repeat;
    background-position: 50% 70%;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    margin-top: -10%;
    gap: 12px;
    z-index: 999;
    cursor: pointer;
  }
  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px)  {
    background-size:cover;
  }

`

const Discordf = styled.button`
display:none;
  &:hover {
    background-color: #3b3b3b;
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 5%;
  }
  @media (max-width: 820px) {
    margin-right: 0%;
    padding: 5px;
    height:6vh;
    border-radius: 20px;
    width: 70%;
    font-size: 0.8rem;
    background-color: #1e1e1e;
    border: none;
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    display: flex;
    margin-right: 2%;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px)  {
   font-size:1.3rem;
   width:50%;
   border-radius:25px;
   .icon {
    width: 30px;
    height: 30px;
  
  }
  }
`;

const WalletButton = styled.button`
display:none;
  &:hover {
    background-color: #3b3b3b;
  }
  .icon {
    width: 35px;
    height: 35px;
  }
  @media (max-width: 820px) {
    margin-right: 0%;
    padding: 5px;
    height:6vh;
    border-radius: 20px;
    width: 70%;
    font-size: 0.8rem;
    background-color: #1e1e1e;
    border: none;
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    display: flex;
    margin-right: 2%;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px)  {
   font-size:1.3rem;
   width:50%;
   border-radius:25px;
   .icon {
    width: 55px;
    height: 45px;
  
  }
  }
`;
const RoundButton = styled.div`
display:none;

@media (max-width: 820px) {

 
  display:flex;
}
`;

const VButton = styled.button`
display:none;
@media (max-width: 820px) {
    
  display:flex;
  position:absolute;
  top:8%;
  right:5%;
  border-radius:25px;
  background-color:transparent;
  color:white;
  border: 1px solid white;
transform:scale(1.3);

}
`;

const LogoMob = styled.img `  
 display:none;
  
    @media (max-width: 820px) {
        width:20%;
        height: 20%;
        transform:scale(1.5);
        display:flex;
        margin-bottom:-7%;
    }
    @media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px)  {
  transform:scale(1.2);
}
`

const ImageDiscordMobile = styled.img`
  @media (max-width: 820px) {
    transform:scale(1.2);
  }
`

const ImageWalletMobile = styled.img`
  @media (max-width: 820px) {
    transform:scale(0.75);
    margin-right: 4%;
  }
`
const NavbarMyAsset: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {user} = useSelector((state:any) => state.auth)
  const {address, connector} = useAccount()
  const navigate = useNavigate()

  const handleMenuToggle = () => {
      setIsMenuOpen(!isMenuOpen);
  };

  const handleNavBack = () => {
    navigate('/collection')
  }


  return (
    <>
    <NavContainer>
        <NavGauche>
            <Logo src = {LogoL}/>
            <CollecButton onClick={handleNavBack}>←BACK</CollecButton>
        </NavGauche>
            <ContainerText>
            <LogoMob src = {LogoL}/>
                <TextGenesis>THE MISSION</TextGenesis>
                <TextEvolution>LACOSTE UNDW3 CARD</TextEvolution>
            </ContainerText>
        <NavDroite>
            <DiscordButton>
                <DiscordNavbar src={Discord} alt="Icon" className="icon" />
                <span className="text">{user.owner.discord_userName}</span>
            </DiscordButton>
            <WalletProfilButton>
                <CoinNavbar src={getWalletLogo(connector?.name!)} alt="Icon2" className="icon2" />
                <span className="text">&nbsp; {address?.slice(0,5) + "..." + address?.slice(38,42)}</span>
            </WalletProfilButton>
            <MobileButton isOpen={isMenuOpen} onClick={handleMenuToggle}>
        </MobileButton>
        <MenuContainer isOpen={isMenuOpen}>
          <Discordf>
            <ImageDiscordMobile src={DiscordMobile} alt="Icon" className="icon" />
            {user.owner.discord_userName}
          </Discordf>
          <WalletButton>
            <ImageWalletMobile src={getWalletLogo(connector?.name!)} alt="Icon" className="icon" />
            {address?.slice(0,7) + "..." + address?.slice(35,42)}
          </WalletButton>
          <VButton onClick={handleMenuToggle}>X</VButton>
        </MenuContainer>
            </NavDroite>
    </NavContainer>

</>
  )
}

export default NavbarMyAsset