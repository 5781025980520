import React from 'react'
import styled from 'styled-components';
import LogoL from '../Assets/LogoReal.svg'
import LanguagesButton from './LanguagesButton';
import Wallet from '../Assets/Wallet.png'
import { ConnectWalletButton } from '../ConnectWalletButton';

const Nav = styled.nav`
   z-index:4;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: white;
    width:100%;
    @media (max-width: 820px) {
        width:100%;
  
          }
`;

const NavElem = styled.div`
   z-index:4;
    display: flex;
    width:90%;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    color: white;
    @media (max-width: 820px) {
        width:95%;
          }
`;
const LogoAndShop = styled.div`
    z-index:4;
    display:flex;
    flex-direction:row;
    align-items: center;
    width:17%;
    justify-content:space-evenly;
    @media (max-width: 820px) {
        width:55%;
        flex-direction:row-reverse;
        justify-content:space-between;
          }
`

const LanguagesAndConnect = styled.div`
    z-index:4;
    display:flex;
    flex-direction:row;
    align-items: center;
    width:17%;
    justify-content:space-evenly;
    @media (max-width: 820px) {
        width:35%;
          }
     @media only screen and (min-width: 900px) and (max-width: 1366px) {    
        width:24%;
     
      }  
      @media only screen and (min-width: 821px) and (max-width: 900px) {    
        width:30%;
     
      }   
            
`

const Logo = styled.img`
    z-index:4;
    width:25%;
    height: 25%;
    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px)  {
      width:20%;
      height: 20%;
    }
    @media screen and (max-width: 900px) and (max-height: 1500px) {

      width:20%;
      height: 20%;
         
         } 
`;

const LogoWallet = styled.img`
    width:20%;
    diplay:flex;
    transform:scale(0.8);
    margin-right:7px;
    margin-bottom:-1%;
    @media (max-width: 820px) {
      margin-right:0px;
      margin-bottom:0%;
      width:100%;
          }
`;
const ShopButton = styled.button`
    z-index:4;
    border-radius: 20px;
    padding: 12px 15px 12px 15px ;
    background-color:#1e1e1e;
    cursor:pointer;
    border: none;
    color: white;
    font-size:0.65rem;
    font-family: 'Space Grotesk', sans-serif;
    font-weight:700;
    text-align: center;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    &:hover{
        background-color:#3B3B3B;
      }
`

const ConnectButton = styled.button`
    z-index:4;
    font-size:0.5rem;
    font-family: 'Space Grotesk', sans-serif;
    border-radius: 20px;
    padding: 8px 15px 8px 15px ;
    background-color:transparent;
    border: 1px solid #02ff79;
    color: white;
    font-weight:700;
    text-align: center;
    display:flex;
    flex-direction:row;
    cursor:pointer;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    &:hover{
      box-shadow: 0 0 10px #02ff79; 
    }
    @media (max-width: 820px) {
    align-items:center;
    justify-content:center;
    padding: 5px 12px 5px 12px ;
        }
`

const TextConnect = styled.p`
   color:white;
   font-size:bold;
   display:flex;
    @media (max-width: 820px) {
     display:none;
          }
`;

const Navbar = () => {
  const handleClickDigitalShpop = () =>{
    window.open('https://www.lacoste.com/be/fr/undw3.html', '_blank', 'noopener,noreferrer')
  }
  return (
    <Nav>
      <NavElem>
        <LogoAndShop>
            <Logo src={LogoL} alt="Logo" />
            <ShopButton onClick={handleClickDigitalShpop}>DIGITAL SHOP</ShopButton>
        </LogoAndShop>
        <LanguagesAndConnect>
            <LanguagesButton/>
            <ConnectWalletButton/>
        </LanguagesAndConnect>
        </NavElem>
    </Nav>
  )
}

export default Navbar