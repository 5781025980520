import { useState } from "react";
import styled from "styled-components";
import PV from "../Assets/PolygonVide.png"
import P1 from "../Assets/Polygon1.png"
import P2 from "../Assets/Polygon2.png"
import P3 from "../Assets/Polygon3.png"
import P4 from "../Assets/Polygon4.png"
import P5 from "../Assets/Polygon5.png"




const Round = styled.div`
    display:flex;
    width: 89%;
    ratio:1/1;
    height: 12vh;
    border-radius: 50%;
    background-color:black;
    align-items:center;
    justify-content:center;
    margin: 0 auto;
    @media (max-width: 820px) {
      height:15vh;
      
    
          }
    @media (max-width: 800px) {
      height: 10vh;
   
         }
         @media (max-width: 325px) {
          height:15vh;
        
        }    
        @media (device-width: 768px) and (device-height: 1024px){    
          height:17vh;
                } 
`;


const Container = styled.div`
    display: grid;
    width:60%;
    gap:10px;
    grid-template-columns: repeat(8, 2fr);
    @media (max-width: 820px) {
      display: flex;
      width:100%;
      gap:20px;
  
         }
`;

const RoundImage = styled.img`
width:100%;
`;

const rounds = [
  {
    id: 1,
    image: P1,

  },
  {
    id: 2,
    image: P2,
   
  },
  {
    id: 3,
    image: P4,
    
  },
  {
    id: 4,
    image: P3,
  },
  {
    id: 5,
    image: P5,

  },
  {
    id: 6,
    image: PV,
   
  },
  {
    id: 7,
    image: PV,
   
  },
  {
    id: 8,
    image: PV,
  },

];

const Ronds = () => {
  const [roundsState, setRoundsState] = useState(rounds);

 

  return (
    <>
    <Container>
      {roundsState.map((round) => {
          return (
            <Round
              key={round.id}>
                 <RoundImage src={round.image} alt="Round Image" />
            </Round>

          );
           })}

    </Container>
    </>
  );
};
export default Ronds;