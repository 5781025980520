import React from 'react'
import styled, {keyframes} from 'styled-components'
import { useState } from 'react';
import DiscordLogo from "../Assets/discord-help.svg"
import { useSelector } from 'react-redux';


const scaleUp = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(10px);
  z-index: 1;

`;

const PopupContainer = styled.div`
    position: fixed;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px; 
    border-bottom-left-radius: 15px; 
    border-bottom-right-radius: 15px; 
    top: 50%;
    left: 50%;
    width:30%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding-bottom: 2rem;
    padding-top: 2rem;
    z-index: 101;
    animation: ${scaleUp} 0.3s ease-out forwards;
    @media (max-width: 800px) {
        width:90%;
        height:60vh;
    }
`;
const Img = styled.img`
    width:12%;
    height:20%;
    @media (max-width: 800px) {
        width:25%;
        margin-top:15%;
       
    }
`
const Title = styled.h1`
    width:80%;
    color:black;
    align-items:center;
    justify-content:center;
    display:flex;
    text-align:center;
    font-size:1.2rem;
    @media (max-width: 800px) {
        font-size:1.2rem;
        width:95%;
       
    }
`
const ChekText = styled.div`
    width:100%
    display:flex;
    flex-direction:row;

`

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    padding:15px;
    @media (max-width: 800px) {
        margin-bottom: -8%;
    }
`;

const CheckboxInput = styled.input`
    margin-right: 1rem;
    transform:scale(1.3);
    align-self:start;
    accent-color:#02ff79;

`
const CheckboxLabel = styled.label`
    font-size: 0.8rem;
    width:75%;
    @media (max-width: 800px) {
        width:90%;
        font-size: 0.7rem;
    }
    
`;

const Button = styled.button<{ disabled: boolean }>`
    background-color: ${({ disabled }) => (disabled ? "#ccc" : "#02ff79")};
    color: black;
    border: none;
    border-radius: 20px;
    width:70%;
    padding: 12px 15px;
    font-weight:bold;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    margin-top: 1rem;
    @media (max-width: 800px) {
        margin-top:30%;
    }
`;
const DontHaveButtun = styled.button`
    background:transparent;
    color: black;
    border: none;
    width:40%;  
    font-size:0.7rem;
    margin-top: 1rem;
    text-decoration:underline;
    @media (max-width: 800px) {
        width:90%;
        margin-top:5%;
    }
`;


const PopUpDiscord = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const {user} = useSelector((state:any) => state.auth)


    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(event.target.checked);
    };
  
    const handleButtonClick = () => {
      if (isChecked) {
        const wallet = user.owner.wallet
        setIsLoading(true)
        window.location.href = `http://localhost:4001/api/discord/discordLogin?wallet=${wallet}`
      }
    }
  
  return (
    <Overlay>
        <PopupContainer>
            <Img src={DiscordLogo}/>
            <Title>Connect Your Discord Account to Boost Your Experience</Title>
            <ChekText>
                <CheckboxContainer>
                    <CheckboxInput
                    type="checkbox"
                    id="myCheckbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    />
                    <CheckboxLabel >
                        Accumsan elementum gravida libero nunc a augue non. Facilisi ornare mollis hendrerit at consectetur et fames tincidunt. Tortor nulla vestibulum venenatis ornare massa placerat facilisis.
                    </CheckboxLabel>
                </CheckboxContainer>
            </ChekText>
            <Button disabled={!isChecked} onClick={handleButtonClick}>
                {isLoading ? "Loading...": "Connect My Discord Account"}
            </Button>
            <DontHaveButtun>I don't have Discord account</DontHaveButtun>
        </PopupContainer>
    </Overlay>
  )
}

export default PopUpDiscord