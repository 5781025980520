import { BrowserRouter, Routes, Route,Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Home from '../Pages/Home';
import Collection from '../Pages/Collection';
import MyAssets from '../Pages/MyAssets';
import Results from '../Pages/Results';
import { useLocation } from 'react-router-dom';
import VerifierMultiHolder from '../Pages/VerifierMultiHolder';
import VerifierDiscord from '../Pages/VerifierDiscord';
import SuccessDiscord from '../Pages/SuccessDiscord';
import Loader from '../Pages/Loader';
import FailedDiscord from '../Pages/FailedDiscord';



const ProtectedRoute = ({ children }) => {
    const { isAuth} = useSelector((state) => state.auth);
    const location = useLocation()
    return isAuth ? children : <Navigate to="/loader" replace state={{from: location}} />;
};


function RouterWrapper() {
    return(
        <>
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/loader' element={<Loader/>}/>
            <Route path='/multiholder' element={
                <ProtectedRoute>
                    <VerifierMultiHolder/>
                </ProtectedRoute>
            }/>
            <Route path='/discord' element={
                <ProtectedRoute>
                    <VerifierDiscord/>
                </ProtectedRoute>
            }/>
            <Route path='/discord/success/:token?/:id?' element={
                <SuccessDiscord/>
            }/>
            <Route path='/discord/failed' element={
                <FailedDiscord/>
            }/>
            <Route path='/collection' element={
            <ProtectedRoute>
                <Collection/>
            </ProtectedRoute>
            }/>
            <Route path='/myassets' element={
            <ProtectedRoute>
                <MyAssets/>
            </ProtectedRoute>
            }/>
            <Route path='/results' element={
            <ProtectedRoute>
                <Results/>
            </ProtectedRoute>
            }/>
        </Routes>
        </>
    )
}

export default RouterWrapper;