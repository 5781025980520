import React from 'react'
import styled from 'styled-components'
import Eclipse5 from "../Assets/diams.png"
import { useState, useEffect, useRef } from 'react'

interface Position {
  x: number;
  y: number;
}

const GenesisContainer = styled.div`
   
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    width:100%;
    height:42%;
    margin-top:-3%;

   
`
const ImgContainer = styled.div`
   
    width:58%;
    height:65%:
    display:flex;
    justify-content: flex-start;
    @media (max-width: 820px) {
      width:100%;
      justify-content: center;
      
         }
           
         
`
const ImgGenesis = styled.img`
border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
    width:100%;
    height:100%;
    display:flex;
    justify-content: flex-start;
    align-items:start;
    @media (max-width: 820px) {
   align-items:center;
      justify-content: center;
      
         }

`
const GenesisIcon = styled.img`
    display:flex;
    justify-content: flex-start;
    align-items:start;
    width:60px;
    height:60px;
    ratio:1/1;
    position:absolute;
    z-index:3;
    padding:15px;
    @media (max-width: 820px) {
      width:60px;
    height:60px;
            }

`
const TitleGenesis = styled.h3`
    color:white;
    display:flex;
    width:50%;
    justify-content: flex-start;
    font-size:1.3rem;
    align-items:start;
   // text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
    @media (max-width: 820px) {
      width:100%;
      font-size:1.2rem;
      
         }
`
const MyGenesis = () => {
  const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const requestRef = useRef<number | null>(null);




  return (
    <GenesisContainer>
        <TitleGenesis>MY UNDW3 CARD</TitleGenesis>
          <ImgContainer>
          <GenesisIcon src = {Eclipse5}/>
          <ImgGenesis src= "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2"/>
          </ImgContainer>
    </GenesisContainer>
  )
}

export default MyGenesis