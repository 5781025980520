import React from 'react'
import styled from 'styled-components'
import { useState } from "react";
import Weekly from './Weekly';
import GlobalRanking from './GlobalRanking';
import Rewards from './Rewards';
import Cadeau from "../Assets/Cadeau.png"
import Icon2 from "../Assets/Icon2.png"
import Icon3 from "../Assets/iconTrophe.png"
import CadeauBlack from "../Assets/BlackCadeau.png"
import Icon2Black from "../Assets/BlackIcon1.png"
import Icon3White from "../Assets/WhiteTrophe.png"

const ContainerWinner = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    margin-top:-2%;
    height:100%;
    
`
const TitleResults = styled.h1`
    font-size:4.5rem;
    display:flex;
    align-items:center;
    justify-content:center;
    color:white;

`
const ContainerButton = styled.div`
    border: none;
    background-color:#1e1e1e;
    width:50%;
    border-top-left-radius: 25px; 
    border-top-right-radius: 25px; 
    border-bottom-left-radius: 25px; 
    border-bottom-right-radius: 25px; 
    padding: 5px;
    display: flex;
    justify-content: space-around;
    @media (max-width: 820px) {
        width:90%;
        height:11vh;
        border-top-left-radius: 15px; 
        border-top-right-radius: 15px; 
        border-bottom-left-radius: 15px; 
        border-bottom-right-radius: 15px; 
         }

    @media (max-width: 325px) {
        width:90%;
        height:16vh;
        border-top-left-radius: 15px; 
        border-top-right-radius: 15px; 
        border-bottom-left-radius: 15px; 
        border-bottom-right-radius: 15px; 
             } 
    @media (device-width: 320px) and (device-height: 568px)  {
        height:13vh;
                 }    
    @media (device-width: 375px) and (device-height: 667px)  {
          height:13vh;
                        }      
    @media (device-width: 414px) and (device-height: 736px)  {
          height:13vh;
                                      }     
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
     height:10vh;
     width:70%;
     margin-top:5%;
          }                                           
`;

const Button = styled.button<{ isActive: boolean }>`
    background-color: ${(props) => (props.isActive ? "#02ff79" : "transparent")};
    color: ${(props) => (props.isActive ? "black" : "#8f8f8f")};
    border: none;
    width:33%;
    font-weight:bold;
    border-top-left-radius: 25px; 
    border-top-right-radius: 25px; 
    border-bottom-left-radius: 25px; 
    border-bottom-right-radius: 25px; 
    box-shadow: ${(props) => (props.isActive ? "0 0 10px #02ff79; " : "none")};
    padding:15px;
    cursor: pointer;
    @media (max-width: 820px) {
     flex-direction:column;
     display:flex;
     align-items:center;
     justify-content:center;
     border-top-left-radius: 15px; 
     border-top-right-radius: 15px; 
     border-bottom-left-radius: 15px; 
     border-bottom-right-radius: 15px; 
         }
  
  }
`;
const Icon1But = styled.img `
    width:7%;
    height: 60%;
    @media (max-width: 820px) {
      width:20%;
    height: 23%;
          }
          @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
            width: 15%;
                 }       
`
const Icon2But = styled.img `
  width:7%;
  height: 60%;
  @media (max-width: 820px) {
    width:20%;
    height: 23%;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
          width: 15%;
               } 

`
const Icon3But = styled.img `
  width:7%;
  height: 60%;
  @media (max-width: 820px) {
    width:20%;
    height: 23%;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
          width: 15%;
               } 
   

`
const Text = styled.span<{ isActive: boolean }>`
    
    color: ${(props) => (props.isActive ? "black" : "1e1e1e")};
    padding: 5px;
    cursor: pointer;
    border-radius: ${(props) => (props.isActive ? "10px" : "0")};
    `;




  const Winners = () => {
    const [activeButton, setActiveButton] = useState("button1");
  
    const handleButtonClick = (buttonId: string) => {
      setActiveButton(buttonId);
    };
  
   
    const weeklyComponent = <Weekly />;
    const globalRankingComponent = <GlobalRanking/>;
    const rewardsComponent = <Rewards />;
  

    let activeComponent;
    if (activeButton === "button1") {
      activeComponent = weeklyComponent;
    } else if (activeButton === "button2") {
      activeComponent = globalRankingComponent;
    } else if (activeButton === "button3") {
      activeComponent = rewardsComponent;
    }
  
    return (
      <ContainerWinner>
     
        <ContainerButton>
          <Button
            isActive={activeButton === "button1"}
            onClick={() => handleButtonClick("button1")}
          >
            {activeButton === "button1" ? (
                <Icon1But src={Icon3} />
              ) : (
                <Icon1But src={Icon3White} />
              )}
            <Text isActive={activeButton === "button1"}>&nbsp;Weekly winners</Text>
          </Button>
          <Button
            isActive={activeButton === "button2"}
            onClick={() => handleButtonClick("button2")}
          >
             {activeButton === "button2" ? (
                <Icon2But src={Icon2Black} />
              ) : (
                <Icon2But src={Icon2}/>
              )}
            <Text isActive={activeButton === "button2"}>&nbsp;Global Ranking</Text>
          </Button>
          <Button
            isActive={activeButton === "button3"}
            onClick={() => handleButtonClick("button3")}
          >
             {activeButton === "button3" ? (
                <Icon3But src={CadeauBlack} />
              ) : (
                <Icon3But src={Cadeau} />
              )}
            <Text isActive={activeButton === "button3"}>&nbsp;Rewards</Text>
          </Button>
        </ContainerButton>
        {activeComponent}
      </ContainerWinner>
    );
  };
  

export default Winners