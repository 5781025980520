import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import { WagmiConfig, createConfig } from "wagmi";
import { ConnectKitProvider, ConnectKitButton, getDefaultConfig } from "connectkit";

import { BrowserRouter } from 'react-router-dom';
import { store } from './app/redux/store';
import { Provider } from "react-redux";
import reportWebVitals from './reportWebVitals';

console.log(process.env.WALLETCONNECT_PROJECT_ID!, process.env.ALCHEMY_ID! )

const config = createConfig(
  getDefaultConfig({
    // Required API Keys
    alchemyId: process.env.ALCHEMY_ID!, // or infuraId
    walletConnectProjectId: process.env.WALLETCONNECT_PROJECT_ID!,

    // Required
    appName: "Lacoste",
    // Optional
    appDescription: "Genesis Pass Evolution",
    appUrl: "https://family.co", // your app's url
  }),
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
      <WagmiConfig config={config}>
        <ConnectKitProvider debugMode customTheme={
          {"--ck-primary-button-hover-background" : "rgba(0, 255, 121, 1)" }
        }>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </ConnectKitProvider>
      </WagmiConfig>
    </Provider>
);
reportWebVitals()

