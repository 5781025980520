import React from 'react'
import styled from 'styled-components'
import NavbarMyAsset from '../Navbar/NavbarMyAsset'
import Badges from '../MyAssetsComponants/Badges'
import GenesisToken from '../MyAssetsComponants/GenesisToken'
import Emerge from '../MyAssetsComponants/Emerge'
import FooterNotHome from '../Footer/FooterNotHome'
import NavbarCollection from '../Navbar/NavbarCollection'


const MyAssets = () => {
  return (
    <>
    <NavbarMyAsset/>
    <Badges/>
    <GenesisToken/>
    <Emerge/>
    <FooterNotHome/>
    </>
  )
}

export default MyAssets