import React from 'react'
import styled from 'styled-components'
import Discord from "../Assets/discord-help.svg"

const JoinUsPageContainer = styled.div`

    width:100%;
    height:20vh;
    display:flex;
    text-align:center;
    align-items:center;
    justify-content:center;
    @media (max-width: 820px) {
        margin-top:-30%;
       
     }
` 
const JoinUsContainer = styled.div`
    width:65%;
    height:15vh;
    margin-left:3%;
    border: none;
    background-color:#1e1e1e;
    border-radius: 5px; 
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px; 
    border-bottom-left-radius: 10px; 
    border-bottom-right-radius: 10px; 
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-around;
    @media (max-width: 820px) {
        width:90%;
        padding:10px;
        height:20vh;
        margin-left:0%;
        flex-direction:column;
     }
     @media (max-width: 325px) {
        width:95%;
        padding:10px;
        height:35vh;
      transform:scale(0.8);
      margin-top:20%;
     } 
   
` 
const DiscordImg = styled.img`
    width:12%;
    height:65%;
    margin-left:2%;
    @media (max-width: 820px) {
    height:70%;
    width:15%;
    margin-left:0%;
 
     }
     @media (max-width: 325px) {
        width:20%;
       
     } 
`
const DiscordText = styled.h1`
    width:50%;
    color:white;
    display:flex;
    justify-content:flex-end;
    text-align:start;
    //text-shadow: 0 0 10px rgba(255, 255, 255, 0), 0 0 20px rgba(255, 255, 255, 0), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
    font-size: 1rem;
    @media (max-width: 820px) {
        font-size: 1rem;
        width:50%;
       
     }
`
const ButtonCall = styled.button`
    height:9%;
    width:40%;
    font-weight:600;
    border-radius: 25px;
    padding:12px 0px 12px 0px;
    background-color:#02ff79;
    align-items:center;
    justify-content:center;
    display:flex;
    color: black;
    font-weight:600;
    font-size:0.76rem;
    cursor:pointer;
    border:none;
    box-shadow: 0 0 5px #007bff; 
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    &:hover{
      background-color: #fff; 
      box-shadow: 0 0 10px #02ff79; 
    }
    @media (max-width: 820px) {
        width:30%;
        padding:20px;
        font-size:0.9rem;
        
     }
    
`

const VerticalLine = styled.div`
    border-left: 0.5px solid white;
    height: 80%; 
    opacity:0.5;
    @media (max-width: 820px) {
        border-left: none;
    border-top: 0.5px solid white;
    width: 90%;
    height: 1px;
      }
`
const Help = styled.h1`
    width:100%;
    color:white;
    display:flex;
    //text-shadow: 0 0 10px rgba(255, 255, 255, 0), 0 0 20px rgba(255, 255, 255, 0), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
    font-size: 2.5rem;
    @media (max-width: 820px) {
        width:50%;
        font-size: 1.7rem;
     }
`
const ImgAndNeed = styled.div`
    display:flex;
    width:40%;
    align-items:center;

    flex-direction:row;
    @media (max-width: 820px) {
        flex-direction:row;
        width:100%;
        align-items:center;
        justify-content:center;
      
     }
     @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
        width:50%;
       
               } 
`
const ButAndDiscord = styled.div`
    display:flex;
    width:40%;
    align-items:center;

    @media (max-width: 820px) {
        flex-direction:row;
        width:100%;
        align-items:center;
        justify-content:center;
        
     }
     @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
        width:50%;
       
               }
`
const NeedHelp = () => {
    const handleOpenDiscord = () =>{
        window.open('https://discord.com/invite/undw3', '_blank', 'noopener,noreferrer')
    }

  return (
    <JoinUsPageContainer>
        <JoinUsContainer>
            <ImgAndNeed>
                <DiscordImg src={Discord}/>&nbsp;&nbsp;
                <Help>NEED HELP ? </Help>
            </ImgAndNeed>
            <VerticalLine/>
            <ButAndDiscord>
                <DiscordText>JOIN THE MISSION ON DISCORD</DiscordText>
                <ButtonCall onClick={handleOpenDiscord}>JOIN US</ButtonCall>
            </ButAndDiscord>
        </JoinUsContainer>
    </JoinUsPageContainer>
  )
}

export default NeedHelp