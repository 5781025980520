export const getLocalStorageValue = (key : string) => {
    try{
        const item  = localStorage.getItem(key)
        if(!item) return null
        return item
    }
    catch(error) {
        return Promise.reject(error)
    }
}

export const setLocalStorageValue = (key : string, value : string) =>{
    value = typeof value !== "string" ? JSON.stringify(value) : value;
    return localStorage.setItem(key,value);
}

export const deleteLocalStorageValue = (name : string) =>{
    return localStorage.removeItem(name)
}