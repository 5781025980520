import React from 'react'
import NavbarElse from '../Navbar/NavbarElse'
import FooterNotHome from '../Footer/FooterNotHome'
import Winners from '../ResultComponents/Winners'

const Results = () => {
  return (
    <>
    <NavbarElse/>
    <Winners/>
  
    </>
  )
}

export default Results