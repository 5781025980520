import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Progress from './Progress'
import Trophy from "../Assets/trophe.png"
import { useNavigate } from 'react-router-dom'

const ScoreContainer = styled.div`
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction:column;
    width:100%;
    height:60%;
   
`
const TextMy = styled.h3`
    display:flex;
    justify-content: flex-start;
    align-items:center;
    color:white;
    //text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
    @media (max-width: 820px) {
        font-size:1rem;
           }
`

const TexScore = styled.h1`
    display:flex;
    justify-content: flex-start;
    align-items:center;
    color:white;
    margin-top:-15%;
    font-size:2.5rem;
    //text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
    @media (max-width: 820px) {
        font-size:1.5rem;
        margin-top:-25%;
           }
`

const ContainerScoreAndText = styled.div`
    display:flex;
    width:75%;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    @media (max-width: 820px) {
        width:100%;
           }

`
const ContainerPts = styled.div`
    display:flex;
    flex-direction:row;
`

const ContainerText = styled.div`
    display:flex;
    flex-direction:column;
`

const ScoreReal = styled.h1`
    color:#02ff79;
  
    font-size:2.5rem;
    //text-shadow: 0 0 10px #02ff79; 
    @media (max-width: 820px) {
        font-size:1.5rem;
        
           }
`

const PtsReal = styled.h5`
    color:#02ff79;
    //text-shadow: 0 0 10px #02ff79; 
    @media (max-width: 820px) {
        font-size:0.7rem;
        
           }
    
    `
const ButtunConainter = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    width:73%;
    @media (max-width: 820px) {
        width:100%;
        
           }
    
`
const ButtonRanking =styled.button`
    border:none;
    text-decoration:underline;
    background:transparent;
    font-size:0.6rem;
    color: white;
    cursor: pointer;
    
`
const Coupe = styled.img`
    width:10%;
    height:60%;
    transform:scale(0.3);
    margin-right:-2%;
    @media (max-width: 820px) {
        transform:scale(0.4);
        
           }

`
const Score: React.FC = () => {
    const [score, setScore] = useState<number>(0)
    const navigate = useNavigate()

    useEffect(() => {
        const targetScore = 3650; // the score you want to reach
        const incrementValue = 30; // how much the score will increment each time
        const intervalTime = 20; // how frequently the score increments (in milliseconds)
    
        const incrementScore = () => {
          setScore((prevScore) => {
            if (prevScore < targetScore) {
              return prevScore + incrementValue;
            }
            return prevScore;
          });
        };
    
        const intervalId = setInterval(incrementScore, intervalTime);
    
        return () => {
          clearInterval(intervalId);
        };
      }, []);

    const handleNavRanking = () => {
        navigate("/results")
    }
  return (
   <ScoreContainer>
        <ContainerScoreAndText>
            <ContainerText>
                <TextMy>MY</TextMy>
                <TexScore>SCORE</TexScore>
            </ContainerText>
            <ContainerPts>
                <ScoreReal>{score}</ScoreReal>
                <PtsReal>PTS</PtsReal>
            </ContainerPts>
        </ContainerScoreAndText>
            <Progress score={2500} total={4290} />
                <ButtunConainter>
                    <Coupe src={Trophy}/>
                    <ButtonRanking onClick={handleNavRanking}>See Ranking</ButtonRanking>
                </ButtunConainter>   
   </ScoreContainer>
  )
}

export default Score