import { useState, useEffect } from "react";
import styled from "styled-components";
import Nft1 from "../Assets/Nft1.png";
import Nft2 from "../Assets/Nft2.png";
import Nft3 from "../Assets/Nft3.png";
import { useSelector } from "react-redux";
import fetchTheEmergeService from "../services/fetchTheEmerge";
interface CardProps {
  tokenId: string;
  imageUrl: string;
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  width: 14%;
  @media (max-width: 820px) {

    flex: 0 0 auto;
    width: 150px; 
    margin: 10px;
  
 
       }
        
  
`;

const Image = styled.img`
  width: 150px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content:flex-start;
  width:100px;
  @media (max-width: 820px) {

    width:100%;
  
 
       }
`;

const Title = styled.p`
  font-size: 0.8rem;
  margin-top: 10px;

  color:white;
  opacity:0.9;
`;
// interface CardProps {
//   name: string;
//   nft: string;
// }

// const cardsData: CardProps[] = [
//   {
//     name: "Nom du NFT",
//     nft: Nft1,
//   },
//   {
//     name: "Nom du NFT",
//     nft: Nft2,
//   },
//   {
//     name: "Nom du NFT",
//     nft: Nft3,
//   },
//   {
//     name: "Nom du NFT",
//     nft: Nft3,
//   },
//     {
//     name: "Nom du NFT",
//     nft: Nft3,
//   },
//   {
//     name: "Nom du NFT",
//     nft: Nft3,
//   },
// ];

const Loader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
`

const CardEmerge = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [ownerEmerge, setOwnerEmerge] = useState<CardProps[]>([])
  const {user} = useSelector((state:any) => state.auth)


  const handleFetchEmerge = async() =>{
    setIsLoading(true)
    const response = await fetchTheEmergeService.getTheEmergeNFTs(user.owner.wallet, user.owner._id)
    if(!response) {
      setIsLoading(false)
      return
    };
    setOwnerEmerge(response.metadata)
    setIsLoading(false)
  }

  useEffect(()=>{
    handleFetchEmerge()
  },[])
  return (
    <>
      {isLoading && <Loader>Getting your NFTs...</Loader>}
      {!isLoading && ownerEmerge.length === 0 && <Loader>Nothing to display</Loader>}
      {!isLoading &&  ownerEmerge!.map((elem) => (
        <Card key={elem.tokenId}>
          <Image src={elem.imageUrl} />
          <TitleContainer>
            <Title>{elem.tokenId}</Title>
          </TitleContainer>
        </Card> 
      ))}
     
    </>
  );
};

export default CardEmerge;