import React from 'react'
import styled from 'styled-components'
import Icons from "../Assets/LogoBlack.png"

const FooterContainer = styled.div`
  width:100%;
  height:8vh;
  background-color:#02ff79;
  flex-direction:row;
  display:flex;
  gap:2%;
  align-items:center;
  justify-content:center;
  @media (max-width: 820px) {
    height:15vh;
    }
  @media (max-width: 800px) {
    height:25vh;
    flex-direction:column;
      }
      @media (max-width: 375px) {
        height:40vh;
        }
      @media (max-width: 325px) {
        height:40vh;
               }  
@media (device-width: 768px) and (device-height: 1024px){    
          height:15vh;
                }             
  `
const HowItWorkButton =styled.button`
  border:none;
  background-color:transparent;
  color:black;
  font-size:0.7rem;
  font-weight:600;

  `
const LacosteText = styled.p`
  color:black;
  font-size:0.7rem;
  font-weight:600;
  `
const LacosteText2 = styled.p`
  color:black;
  font-size:0.7rem;
  font-weight:600;
  `
const LacosteText3 = styled.p`
  color:black;
  font-size:0.7rem;
  font-weight:600;
  `
const LacosteMentionLegal = styled.p`
  color:black;
  font-size:0.7rem;


  `
const Point = styled.div`
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 50%;
  @media (max-width: 800px) {
    display:none;
      }
  `
const Icon = styled.img`
  width:15%;
 margin-bottom:-2%;

`
const Footer = () => {
  return (
    <FooterContainer>
      <HowItWorkButton>How it works ?</HowItWorkButton>
        <Point/>
      <LacosteText>Lacoste UNDW3</LacosteText>
        <Point/>
      <LacosteText2>Terms of sales</LacosteText2>
        <Point/>
      <LacosteText3>Legal Notice</LacosteText3>
        <Point/>
      <LacosteMentionLegal>
        <Icon src ={Icons}/> &nbsp; Lacoste© All Rights reserved</LacosteMentionLegal>
    </FooterContainer>
  )
}

export default Footer