import styled from "styled-components";
import DiscordLogo from "../Assets/discord-help.svg"
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import BackLog from "../Assets/backLog.png"

const OverlayGlobal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  background-image: url(${BackLog});
  background-repeat: norepeat;
  background-size: cover;
  z-index: 1;

`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 1;

`;

const PopupContainer = styled.div`
    position: fixed;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px; 
    border-bottom-left-radius: 15px; 
    border-bottom-right-radius: 15px; 
    top: 50%;
    left: 50%;
    width:30%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding-bottom: 2rem;
    padding-top: 2rem;
    z-index: 101;
    @media (max-width: 800px) {
        width:90%;
        height:50vh;
        padding-bottom: 0rem;
        padding-top: 0rem;
    }
`;
const Img = styled.img`
    width:12%;
    height:20%;
    @media (max-width: 800px) {
        width:25%;
        margin-top:15%;
       
    }
`
const Title = styled.h1`
    width:80%;
    color:black;
    align-items:center;
    justify-content:center;
    display:flex;
    text-align:center;
    font-size:1.2rem;
    @media (max-width: 800px) {
        font-size:1.2rem;
        width:95%;
       
    }
`

const SuccessDiscord  = () =>{
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() =>{
        const urlParams = new URLSearchParams(location.search)
        const token = urlParams.get('token')
        const discordId = urlParams.get('id')
        if(token && discordId){
            // localStorage.setItem('discord_token', token);
            // localStorage.setItem('discord_id', discordId);
            // console.log("Logged in success,", token, discordId)
            setTimeout(() =>{
                navigate("/collection")
            },1800)
        }
    },[location])

    return(
    <OverlayGlobal>
        <Overlay>
            <PopupContainer>
                <Img src={DiscordLogo}/>
                <Title>Discord Login Successfull !</Title>
            </PopupContainer>
        </Overlay>
    </OverlayGlobal>
    )
}

export default SuccessDiscord;