import axios, {AxiosResponse, AxiosError} from 'axios'
import { getLocalStorageValue, deleteLocalStorageValue } from '../utils/localStorage.utils'

const api = axios.create({
    baseURL: "http://localhost:4001/raffles",
})


api.interceptors.response.use(
    async (response: AxiosResponse) => {
      // Handle response data
      return response;
    },
    async (error: AxiosError) => {
      // Handle error
      if (error.response?.status === 400) {
        // Redirect to login if session expired
        alert('Session Expirée')
        window.location.href = '/';
      }
      return Promise.reject(error);
    },
);


const fetchLastRaffle = async(wallet: string, userId: string) => {
    try{
        const token = getLocalStorageValue('ac_token');
        if(!token) return;
        const headers = {
            'Authorization' : `Bearer ${token}`
        }
        const response : AxiosResponse = await api.post("/getLastRaffleWinners", {
            wallet: wallet,
            userId: userId
        },{headers})    
        return response.data

    }
    catch(error){
        console.log(error)
        Promise.reject(error)
        return null
    }
}

const fetchAllRaffles = async(wallet: string, userId: string) => {
    try{
        const token = getLocalStorageValue('ac_token');
        if(!token) return;
        const headers = {
            'Authorization' : `Bearer ${token}`
        }
        const response : AxiosResponse = await api.post("/getAllRaffles", {
            wallet: wallet,
            userId: userId
        },{headers})    
        return response.data

    }
    catch(error){
        console.log(error)
        Promise.reject(error)
        return null
    }
}


const fetchRaffleByDate = async(wallet: string, userId: string, date: string) => {
    try{
        const token = getLocalStorageValue('ac_token');
        if(!token) return;
        const headers = {
            'Authorization' : `Bearer ${token}`
        }
        const response : AxiosResponse = await api.post("/getRaffleByDate", {
            wallet: wallet,
            userId: userId,
            date: date
        },{headers})    
        return response.data

    }
    catch(error){
        console.log(error)
        Promise.reject(error)
        return null
    }
}

const fetchAvailableDates = async(wallet: string, userId: string) => {
    try{
        const token = getLocalStorageValue('ac_token');
        if(!token) return;
        const headers = {
            'Authorization' : `Bearer ${token}`
        }
        const response : AxiosResponse = await api.post("/getAvailableDates", {
            wallet: wallet,
            userId: userId,
        },{headers})    
        return response.data

    }
    catch(error){
        console.log(error)
        Promise.reject(error)
        return null
    }
}

const fetchNextRaffleName = async(wallet: string, userId: string) => {
    try{
        const token = getLocalStorageValue('ac_token');
        if(!token) return;
        const headers = {
            'Authorization' : `Bearer ${token}`
        }
        const response : AxiosResponse = await api.post("/getNextRaffleName", {
            wallet: wallet,
            userId: userId,
        },{headers})    
        return response.data

    }
    catch(error){
        console.log(error)
        Promise.reject(error)
        return null
    }

}


const raffleService = {fetchLastRaffle, fetchAllRaffles, fetchRaffleByDate, fetchAvailableDates, fetchNextRaffleName}

export default raffleService;

