import React from 'react'
import styled from 'styled-components'
import Gif from "../Assets/gifgif.gif" 



const QuestionContainer = styled.div`
    width:100%;
    height:70vh;
    display:flex;
    padding-top:2%;
    align-items:center;
    jusitfy-content:center;
    flex-direction:column;
    @media (max-width: 820px) {
       margin-top:35%;
       padding-top:0%;
    }
    @media (device-width: 375px) and (device-height: 667px){
        height:100vh;
                      } 
    @media (max-width: 325px) {
        height:130vh;
            }
    @media (device-width: 375px) and (device-height: 812px) {
        height:80vh;
        margin-top:10%;
            }        
    @media (device-width: 414px) and (device-height: 896px)      
            {
        height:80vh;
        margin-top:-1%;
        }  
    @media (device-width: 320px) and (device-height: 568px){
        height:105vh;
        margin-top:-1%;
        
     }  
     @media (device-width: 320px) and (device-height: 658px){
        height:90vh;
               }
    @media (device-width: 360px) and (device-height: 740px){
        height:90vh;
        margin-top:45%;
                       }   
    @media only screen and (device-width: 768px) and (device-height: 1024px)  {
        height:75vh;
        padding-top:0%;
        }  
        @media only screen and (min-width:1250px) and (max-width: 1390px) {    
            height:80vh;
            padding-top:0%;
        } 
                       
                                                     
    
` 
const GifImage = styled.img`
    width: 20%;
    height: auto;
    margin-top:-5%;
    @media (max-width: 820px) {
        width: 60%;
     }
     @media only screen and (device-width: 768px) and (device-height: 1024px)  {
        width: 40%;
                   }   
                                   
`;

const TitleQuestion = styled.h1`
    text-align:center;
    width:40%;
    line-height:1.1em;
    justify-content:center;
    align-items:center;
    font-size: 3rem;
    color: white;
    text-shadow:  rgba(255,255,255,0.9) 0px 0px 6px; 
    @media (max-width: 820px) {
        font-size: 2.2rem;  
        width:85%;
        line-height:1em;

    }

        `;

const TextQuestion = styled.p`
    width:40%;
    color:white;
    font-size:0.9rem;
    text-align:center;
    line-height:1.7em;
    //text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
    @media (max-width: 820px) {
        font-size:0.9rem;
        width:85%;
        display:flex;
        justify-content:center;
        align-items:center;
        text-align:center;
    }
    @media (max-width: 325px) {
        font-size:0.8rem;
               }
    `   
const ButtonQuestion = styled.button`
    margin-top:2%;
    font-weight:600;
    border-radius: 20px;
    font-size:0.72rem;
    font-family: 'Space Grotesk', sans-serif;
    padding: 15px 25px 15px 25px ;
    background-color:#02ff79;
    color: black;
    text-align: center;
    cursor:pointer;
    border:none;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    &:hover{
      background-color: #fff; 
      box-shadow: 0 0 10px #02ff79; 
    }
    @media (max-width: 820px) {
       transform:scale(0.9);
       border-radius: 25px;
       margin-top:5%;
       padding: 15px 55px 15px 55px ;
       font-size:1rem;
    }
`

const Question = () => {
  return (
    <QuestionContainer>
        <GifImage src={Gif} alt="GIF"/>
        <TitleQuestion>DON'T HAVE YOUR GENESIS YET ?</TitleQuestion>
        <TextQuestion>
        To participate in the The Mission and progress in the community,
you must have an NFT Genesis pass.
        </TextQuestion>
        <ButtonQuestion>GET YOUR GENESIS NOW</ButtonQuestion>
    </QuestionContainer>
  )
}

export default Question