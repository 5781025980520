import React from 'react';
import styled from 'styled-components';
import Rond1 from "../Assets/Rond1.png"
import Rond2 from "../Assets/Rond2.png"
import Rond3 from "../Assets/Rond3.png"

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  height: 80vh;
  width:75%;
  gap:10px;
    margin:0 auto;
    margin-top:8%;
    @media (max-width: 820px) {
        width:95%;
        margin-top:-15%;
     
       }
       @media (max-width: 325px) {
     gap:5px;
     margin-top:15%;
       }   
       @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    
       
                     } 

`;
const Card = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-end;
width: 25%;
background-color: #1e1e1e;
border-radius: 10px;
height:42vh;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
padding-bottom:5px;
position: relative;

&::before {
  content: '';
  position: absolute;
  top: -15%;
  z-index:3;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 120px;
  background-image: url(${Rond2});
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-bottom: 20px;
}
@media (max-width: 820px) {
    height:25vh;
    width:30%;
    padding-bottom:10px;
    &::before {
        width: 90px;
        height: 90px;
    }
   }
   @media (max-width: 325px) {
    &::before {
        width: 50px;
        height: 50px;

    }
   }  
   @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    width:28%;
                 }  
`;
const Card2 = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-end;
width: 25%;
background-color: #1e1e1e;
border-radius: 10px;
padding-bottom:5px;
height:50vh;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

position: relative;

&::before {
  content: '';
  position: absolute;
  top: -15%;
  left: 50%;
  z-index:3;
  transform: translateX(-50%);
  width: 140px;
  height: 140px;
  background-image: url(${Rond1});
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-bottom: 20px;
}
@media (max-width: 820px) {
    height:30vh;
    width:40%;
    padding-bottom:10px;
    &::before {
        width: 100px;
        height: 100px;
    }
   }
   @media (max-width: 325px) {
    &::before {
        width: 70px;
        height: 70px;
    }
   }  
   @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    width:30%;
                 }  

`;
const Card3 = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-end;
padding-bottom:5px;
width: 25%;
background-color: #1e1e1e;
border-radius: 10px;
height:35vh;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

position: relative;

&::before {
  content: '';
  z-index:3;
  position: absolute;
  top: -15%;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  background-image: url(${Rond3});
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-bottom: 20px;
}
@media (max-width: 820px) {
    height:25vh;
    width:30%;
    padding-bottom:10px;
    &::before {
        width: 70px;
        height: 70px;
    }
   }
   @media (max-width: 325px) {
    &::before {
        width: 50px;
        height: 50px;
    }
   } 
   @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    width:26%;
    height:21vh;
                 }  
`;

const CardImage = styled.img`
  width: 85%;
  height: 65%;
  ratio:1/1;
  background-color: #f1f1f1;
  border-radius: 10px;
  position:absolute;
  top:5%;
  @media (max-width: 820px) {
    top:10%;
    height: 45%;

    }
    @media (max-width: 325px) {
       top:5%;
       height: 55%;
       } 
       @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
        top:5%;
        height: 52%;
                     }      
`;


const CardTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color:white;
  margin: 0;
  padding-bottom:8px;
  @media (max-width: 820px) {
    font-size:1rem;

    }
    @media (max-width: 325px) {
      font-size:0.8rem;
      padding-bottom:0px;
        } 
`;

const CardText = styled.p`
  font-size: 2rem;
  text-align: center;
color:white;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:row;
  margin:0 auto;
  @media (max-width: 820px) {
    font-size:1rem;

    }
    @media (max-width: 325px) {
        font-size:0.8rem;
      
          } 
   @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
        font-size:1.4rem;
                         } 
 
`;
const TextPts = styled.h4`
  font-size:0.7rem;
  color:white;
  align-items:start;
 margin-left:-10%;
  height:3vh;
  display:flex;
  @media (max-width: 325px) {
    font-size:0.6rem;
    padding-bottom:0px;
      } 
`
const CardDescription = styled.div`
    font-size: 1.8rem;
    font-weight:bold;
    flex-direction:row;
    display:flex;
    align-items:center;
    justify-content:center;
    width:50%;
    height:5vh;
    background-color:black;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    @media (max-width: 820px) {
      width:75px;
    
        }
        @media (max-width: 325px) {
          margin-bottom:-3%;
          margin-top:4%;
            width:70px;
              } 
              @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
                width:50px;
                padding:0px 40px;
                             }              
    `

const Podium2 = () => {
  return (
    <CardContainer>
      <Card >
      <CardImage src='https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2'/>
       
        <CardTitle>ID DISCORD</CardTitle>
         <CardDescription>
            <CardText>2039</CardText>
            <TextPts>PTS</TextPts>
        </CardDescription>
      </Card>
      <Card2 >
        <CardImage src='https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2'/>
        <CardTitle>ID DISCORD</CardTitle>
        <CardDescription>
            <CardText>3545</CardText>
            <TextPts>PTS</TextPts>
        </CardDescription>
      </Card2>
      <Card3 >
      <CardImage src='https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2'/>
        <CardTitle>ID DISCORD</CardTitle>
        <CardDescription>
            <CardText>2567</CardText>
            <TextPts>PTS</TextPts>
        </CardDescription>
      </Card3>
    </CardContainer>
  );
};

export default Podium2;
