import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Diams from "../Assets/diams.png"
import VCkeck from "../Assets/VChek.png"
import VNot from "../Assets/VNot.png"
import Ouvert from "../Assets/Ouvert.png"
import Fermer from "../Assets/Fermer.png"
import pointIcon from "../Assets/TropheVert.png"
import lockedIcon from "../Assets/LockedLum.png"
import P2 from "../Assets/Polygon4.png"
import P3 from "../Assets/Polygon3.png"
import P4 from "../Assets/Polygon5.png"

interface Data {
    image: string;
    mission: string;
    expliquation: string;
    answer: Answer[];
    locked: boolean;
}

interface Answer {
    missions: string;
    titre: string;
    subtitle: string;
    point: string;
    check: boolean;
}
  const CardData: Data[] = [
    {
        image:  Diams,
        mission: "CHAPTER 1",
        expliquation: "IT'S ALL ABOUT QUESTING",
       
        answer: [
            {
                missions: "MISSION 1",
                titre: "The beginning",
                subtitle: "First day participating in The Mission",
                point: "20 Points",
                check: false,
            },
            {
                missions: "MISSION 2",
                titre: "holding quest",
                subtitle: "How long have you been among the crocodiles?",
                point: "+100 days = 50 points / -100 days = 20 points",
                check: false,
            },
            {
                missions: "MISSION 3",
                titre: "enigma",
                subtitle: "Be among the fastest to solve the Lacoste UNDW3 Card puzzle on Discord",
                point: "Top 3 fastest individuals receive 50 points each",
                check: false,
            },
            {
                missions: "MISSION 4",
                titre: "Vote",
                subtitle: "Vote on the Discord post this week",
                point: "10 points for each vote",
                check: false,
            },
            {
                missions: "MISSION 5",
                titre: "factory week",
                subtitle: "Showcase your creativity on the factory and earn points if your creation is favored by the community",
                point: "30 points for the top 3 winners",
                check: false,
            },
            {
                missions: "MISSION 6",
                titre: "Og discord",
                subtitle: "Are you a Discord expert?",
                point: "On the server for over 100 days = 50 points / less than 100 days = 20 points",
                check: true,
            },
            {
                missions: "MISSION 7",
                titre: "enigma",
                subtitle: "Be among the first 10 to solve the puzzle on Discord",
                point: "Small puzzle announced by HDG, the first 10 rewarded with 30 points each",
                check: false,
            },
            {
                missions: "MISSION 8",
                titre: "vote",
                subtitle: "Vote on the Discord post this week",
                point: "10 points for each vote",
                check: false,
            },
            {
                missions: "MISSION 9",
                titre: "factory week",
                subtitle: "Showcase your creativity on the factory and earn points if your creation is favored by the community",
                point: "30 points for the top 3 winners",
                check: false,
            },
            {
                missions: "MISSION DAILY",
                titre: "Daily activity",
                subtitle: "",
                point: "Maximize Discord points",
                check: false,
            },
           
        ],
        locked: false,
    },
    {    
    image:  P2,
    mission: "CHAPTER 2",
    expliquation: "WHO IS HENRY DE GAYETTE ?",
   
    answer: [
        {
            missions: "MISSION 1",
            titre: "",
            subtitle: "",
            point: "",
            check: false,
        },
    ],
    locked: true,
},
{    
    image:  P3,
    mission: "CHAPTER 3",
    expliquation: "A CROCO IS A CROCO",
    locked: true,
    answer: [
        {
            missions: "MISSION 1",
            titre: "",
            subtitle: "",
            point: "",
            check: false,
        },
    ],
},
{    
    image:  P4,
    mission: "CHAPTER 4",
    expliquation: "THE EMERGE",
    locked: true,
    answer: [
        {
            missions: "MISSION 1",
            titre: "",
            subtitle: "",
            point: "",
            check: false,
        },
    ],
},
    
  ];

  const Container = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ isExpanded }) => (isExpanded ? "70vh" : "70vh")};
  transition: height 0.5s ease-in-out;
  @media (max-width: 820px) {
    margin-top: 15%;
    height: auto;
    margin-bottom:60%;
  }

`;

const TextContainer = styled.div`
  display:flex;
  justify-content:flex-start;
  align-items: center;
  flex-direction:column;
  width:80%;
  @media (max-width: 820px) {
      width:100%;
  }
`

const TitleMyMission = styled.h1`
    //text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
    font-size:1.3rem;
    display:flex;
    text-align:center;
    justify-content:flex-start;
    width:90%;
    margin-top:-2%;
    color:white;
    span {
        color: #02ff79; 
        text-shadow: #02ff79 0px 0px 6px;
      }
    @media (max-width: 820px) {
        font-size:1.2rem;
        width:100%;
    }
`
const ConainerCard = styled.div`
    background-color: #1e1e1e;
    border: none;
    border-radius:15px;
    padding: 5px 5px 7px 5px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    width: 28vw;
    font-weight: bold;
    margin-top:1%;
    cursor: pointer;
    @media (max-width: 820px) {
        padding: 0px 5px 10px 5px;
        width: 85vw;
        transform:scale(0.98);
    }
    @media (max-width: 325px) {
      width: 95vw;
      padding: 0;
      transform:scale(0.9);
  
    }
   

`;
const AllInCard = styled.div`
    display:flex;
    flex-direction:row;
    text-align:center;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
      margin-top:2%;
    } 
`
const ContainerImage = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-start;
    padding-left:3%;
    width:18 %;
    height:18%;
    @media (max-width: 820px) {
        height:35%;
        width:22%;
    }
`

const Icon = styled.img<{ locked: boolean }>`
  width: ${(props) => (props.locked ? "60px" : "68px")};
  height: ${(props) => (props.locked ? "65px" : "65px")};
  ratio:1/1;
  opacity: ${(props) => (props.locked ? 0.3 : 1)};
`;

const TextAndProgress = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:start;
    flex-direction:column;
    width:80%;
    padding-left:6%;
    margin-top:2%;
`
const TitleMission= styled.h1`
    display:flex;
    align-items:center;
    width:100%;
    font-size:0.7rem;
    color:white;
`
const ContainerButton = styled.div`
    display:flex;
    width:10%;
    justify-content:flex-end;
`
const ButtonClickIcon = styled.button`
    
    background-color:transparent;
    border:none;
    color:#02ff79;
    font-size:1.5rem;
    align-items:center;
      @media (max-width: 820px) {
        font-size:1.5rem;
        margin-top:2%;
      }
`
const TitleExplication = styled.h4`
  //text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
  font-size: 0.8rem;
  color:white;
  margin-top:-4%;
  text-transform: uppercase;
  width:100%;
  padding:1%;
  @media (max-width: 820px) {
      align-items:start;
      display:flex;
      text-align:start;
      
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    margin-top:-2%;
  } 
    
`
const ContainerProgressBar = styled.div`
  width:100%;
  display:flex;
  align-tiems:center;
  text-align:center;
  justify-content:center;
  margin-top:-2%;
`

const Open = styled.div`
 
`
const On = styled.img`
 
`
const OpenInside = styled.div<{ isVisible: boolean }>`
    max-height: ${props => props.isVisible ? "200px" : "0"};
    overflow-y: scroll;
    overflow-x:hidden;
    height: ${props => props.isVisible ? "auto" : "0"};
    margin-top: ${props => props.isVisible ? "2%" : "0"};
    font-weight:100;
    width: 98%;
    color:white;
    font-size:0.6rem;
    background-color: #1e1e1e;
    transition: all 2s ease-in-out;
    @media (max-width: 820px) {
        font-size:1rem;
        transition: height 0.5s ease-in-out, margin 0.5s ease-in-out;
        max-height: ${props => props.isVisible ? "1200px" : "0"};
        height: ${props => props.isVisible ? "100%" : "0"};
        margin-top: ${props => props.isVisible ? "2%" : "0"};
        overflow-y: hidden;
    }
       
`;

const AnswerContainer = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.2px solid #aaa;
    padding: 20px;
    @media (max-width: 325px) {
      padding: 10px;
    }
`;

const AnswerLeft = styled.div`
    display:flex;
    flex-direction: column;
    @media (max-width: 325px) {
      width:85%;
    }
`;

const AnswerTitle = styled.h4`
    margin: 0;
    color: #fff;
    text-transform: uppercase;
    padding-top:6px;
    font-size:0.7rem;
    @media (max-width: 325px) {
      padding: 10px;
    }
`;

const AnswerSubtitle = styled.p`
    margin: 0;
    color: white;
    width:300px;
    padding-top:6px;
    font-size:0.7rem;
    @media (max-width: 325px) {
      width:95%;
    }
`;


const AnswerPoint = styled.span`
    margin: 0;
    color: #02ff79;
    align-items:center;
    flex-direction:row;
    display:flex;
    font-weight:bold;
    padding-top:8px;
    font-size:0.7rem;
    width:90%;
    @media (max-width: 325px) {
      width:95%;
     }
`;

const AnswerTitreContainer = styled.div`
   display:flex;
   flex-direction:row;
   gap:10px;
`;
const LockedCard = styled(ConainerCard)`
  opacity: 0.8;
`;
const LockedIcon = styled.img`
  width: 40px;
  height: 65%;
`;

const UnlockedIcon = styled.img`
width: 40px;
  height: 35%;
  transform:scale(0.6);
`;
const AnswerList = ({ answers }: { answers: Answer[] }) => (
    <>
        {answers.map((answer, i) => (
            <AnswerContainer key={i}>
                <AnswerLeft>
                    <AnswerTitreContainer>
                    <AnswerTitle>{answer.missions}</AnswerTitle>
                    <AnswerTitle> {answer.titre}</AnswerTitle>
                    </AnswerTitreContainer>
                    <AnswerSubtitle>{answer.subtitle}</AnswerSubtitle>
                    <AnswerPoint>
                        <On src={pointIcon} alt="point icon" style={{ marginRight: '5px' }} />
                    {answer.point}
                    </AnswerPoint>
                </AnswerLeft>
                <img src={answer.check ? VCkeck : VNot} alt="checkbox" />
            </AnswerContainer>
        ))}
    </>
);

  const Mission = () => {
    const [activeMission, setActiveMission] = useState<number | null>(null);
    const [score, setScore] = useState<number>(1111);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const handleButtonClick = () => {
      setIsExpanded((prevState) => !prevState);
    };
  

    return (
      <Container isExpanded={isExpanded}>
          <TextContainer>
            <TitleMyMission>MY MISSIONS :&nbsp; <span> SAISON 1</span></TitleMyMission>
          </TextContainer>
          {CardData.map((Card, index) => (
  <div key={index}>
    {Card.locked ? (
      <LockedCard>
        <AllInCard>
        <ContainerImage>
        <Icon src={Card.image} locked={Card.locked} />
        </ContainerImage>
        <TextAndProgress>
          <TitleMission>{Card.mission}</TitleMission>
          <TitleExplication>{Card.expliquation}</TitleExplication>
        </TextAndProgress>
        <ContainerButton>
        <ButtonClickIcon>
        <LockedIcon src={lockedIcon} />
        </ButtonClickIcon>
        </ContainerButton>
        </AllInCard>
      </LockedCard>
    ) : (
      <ConainerCard>
        <AllInCard>
          <ContainerImage>
          <Icon src={Card.image} locked={Card.locked} />
          </ContainerImage>
          <TextAndProgress>
            <TitleMission>{Card.mission}</TitleMission>
            <TitleExplication>{Card.expliquation}</TitleExplication>
          </TextAndProgress>
          <ContainerButton>
            <ButtonClickIcon onClick={() => setActiveMission(activeMission === index ? null : index)}>
              {activeMission === index ? <UnlockedIcon src={Ouvert} /> : <UnlockedIcon src={Fermer} />}
            </ButtonClickIcon>
          </ContainerButton>
        </AllInCard>
        {activeMission === index && (
          <Open>
            <OpenInside isVisible={!Card.locked}>
              <AnswerList answers={Card.answer} />
            </OpenInside>
          </Open>
        )}
      </ConainerCard>
    )}
  </div>
))}

      </Container>
    );
  };
  

export default Mission  