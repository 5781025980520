import axios, {AxiosResponse, AxiosError} from 'axios'
import { getLocalStorageValue, deleteLocalStorageValue } from '../utils/localStorage.utils'

const api = axios.create({
    baseURL: "http://localhost:4001/getNFTsEmerge",
})


api.interceptors.response.use(
    async (response: AxiosResponse) => {
      // Handle response data
      return response;
    },
    async (error: AxiosError) => {
      // Handle error
      if (error.response?.status === 400) {
        // Redirect to login if session expired
        alert('Session Expirée')
        window.location.href = '/';
      }
      return Promise.reject(error);
    },
);


const getTheEmergeNFTs = async(wallet: string, userId: string) => {
    try{
        const token = getLocalStorageValue('ac_token');
        if(!token) return;
        const headers = {
            'Authorization' : `Bearer ${token}`
        }
        const response : AxiosResponse = await api.post("/getTheEmerge", {
            wallet: wallet,
            userId: userId
        },{headers})
                
        return response.data

    }
    catch(error){
        console.log(error)
        Promise.reject(error)
        return null
    }
}

const fetchTheEmergeService = {getTheEmergeNFTs}

export default fetchTheEmergeService;