import { BrowserRouter, Routes, Route} from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Home from '../Pages/Home';
import Collection from '../Pages/Collection';
import MyAssets from '../Pages/MyAssets';
import Results from '../Pages/Results';
import RouterWrapper from './Routes';
import { isLoginByToken } from './redux/slices/authSlice';

function App() {
  const dispatch = useDispatch()

  useEffect( () => {
    dispatch(isLoginByToken());
  },[dispatch])

  return <RouterWrapper/>;
}

export default App;

