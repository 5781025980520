import React, { useState } from 'react'
import styled from 'styled-components'
import RoundGrid from './RoundGrid'
import PopUp from './PopUp'

const CollectionContainer = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;
    width:100%;
    height:60%;
    @media (max-width: 820px) {
      margin-top:10%;
    }
`
const All = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;
    width:100%;
    height:60%;
    @media (max-width: 820px) {
      justify-content:flex-start;
      align-items:start;
    }
`

const TextMy = styled.h3`
    display:flex;
    justify-content: flex-start;
    align-items:start;
    color:white;
    //text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
    @media (max-width: 820px) {
      font-size:1rem;
    }
`
const TexCollection = styled.h1`
    display:flex;
    justify-content: flex-start;
    align-items:start;
    color:white;
    margin-top:-7%;
    font-size:2.5rem;
    //text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
    @media (max-width: 820px) {
      font-size:1.5rem;
    }
`
const RoundGridContainer = styled.div`
    margin-top:-2%;
    width:55%;
    align-items:center;
    display:flex;
    justify-content:center;
    @media (max-width: 820px) {
      width:100%;
    }
`
const TextCont = styled.div`
    display:flex;
    width:53%;
    justify-content:flex-start;
    flex-direction:column;
`

const MyCollection = () => {
  return (  
    <CollectionContainer>
      <All>
        <TextCont>
          <TextMy>MY</TextMy>
          <TexCollection>COLLECTION</TexCollection>
        </TextCont>
        <RoundGridContainer>
            <RoundGrid/>
        </RoundGridContainer>
      </All>
    </CollectionContainer>
  )
}

export default MyCollection