import { ConnectKitButton } from "connectkit";
import styled,{keyframes} from "styled-components";
import Wallet from '../Assets/Wallet.svg'
import React,{ useState, useEffect }from 'react';
const Pulse = keyframes`
  0% {
    filter: brightness(1);
    transform: scale(1.0);
  }
  25% {
    filter: brightness(1.1);
    transform: scale(1.03);
  }
  50% {
    filter: brightness(1);
    transform: scale(1.0);
  }
  100% {
    transform: scale(1);
  }
`

const StyledButton = styled.button`
    z-index:4;
    font-size:0.6rem;
    font-family: 'Space Grotesk', sans-serif;
    border-radius: 20px;
    width:100%;
    padding: 8px 0px 8px 12px ;
    background-color:transparent;
    border: 1px solid #02ff79;
    color: white;
    font-weight:700;
    text-align: center;
    display:flex;
    align-items:center;
    flex-direction:row;
    cursor:pointer;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    animation: ${Pulse} 1.2s cubic-bezier(0.77, 0, 0.175, 1) forwards infinite;
    &:hover{
      box-shadow: 0 0 10px #02ff79; 
    }
    @media (max-width: 820px) {
        align-items:center;
        justify-content:center;
        transform:scale(0.9);
        padding: 5px 12px 5px 12px ;
    }
    @media (max-width: 325px) {
      transform:scale(1);
    }

    @media only screen and (device-width: 768px) and (device-height: 1024px)  {
        padding: 5px 5px 5px 5px ;
        width:35%;
    }     
    @media only screen and (min-width: 1000px) and (max-width: 1366px) {    
        align-items:center;
        justify-content:center;
        padding: 8px 20px ;
        
      }   
      @media screen and (max-width: 900px) and (max-height: 1500px) {

        width:50px;
           
           } 
           @media only screen and (min-width: 821px) and (max-width: 900px) {    
            width:150px;
         
                         

`;

const LogoWallet = styled.img`
    width:17%;
    diplay:flex;
    transform:scale(0.8);
    margin-right:10px;
    margin-bottom:-1%;
    @media (max-width: 820px) {
      margin-right:0px;
      margin-bottom:0%;
      width:70%;
    }
    @media (max-width: 325px) {
      transform:scale(1);
      width:100%;
    }
    @media only screen and (device-width: 768px) and (device-height: 1024px)  {
        transform:scale(1);
        width:25%;
    }      
`;



export const ConnectWalletButton = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 821);
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize); 
    };
  }, []);

  return (
    <ConnectKitButton.Custom>
      {({ isConnected, show, truncatedAddress, ensName }) => {
        return (
          <StyledButton onClick={show}>
           <LogoWallet src={Wallet} alt="Icon" className="icon" />
            {isConnected ? ensName ?? truncatedAddress : isMobile ? "" : "CONNECT WALLET"}
          </StyledButton>
        );
      }}
    </ConnectKitButton.Custom>
  );
};