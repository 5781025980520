import styled from "styled-components";
import { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getLocalStorageValue } from "../utils/localStorage.utils";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(10px);
  z-index: 1;

`;

const PopupContainer = styled.div`
    position: fixed;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px; 
    border-bottom-left-radius: 15px; 
    border-bottom-right-radius: 15px; 
    top: 50%;
    left: 50%;
    width:30%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    padding-bottom: 2rem;
    padding-top: 2rem;
    z-index: 101;
    @media (max-width: 800px) {
        width:90%;
        height:60vh;
    }
`;
const Title = styled.h1`
    width:80%;
    color:white;
    align-items:center;
    justify-content:center;
    display:flex;
    text-align:center;
    font-size:1.2rem;
    @media (max-width: 800px) {
        font-size:1.2rem;
        width:95%;
       
    }
`
const Loader = () => {
    const navigate = useNavigate()
    const {isAuth, user} = useSelector((state: any) => state.auth);
    const location = useLocation()

    useEffect(() =>{
        const token = getLocalStorageValue("ac_token")
        if(!token) navigate('/')
    },[])

    useEffect(() => {
        const from  = location.state?.from || '/collection'
        if(isAuth && user.owner.isMultiHolder && !user.owner.choosenTokenId ) navigate("/multiholder");
        if(isAuth && user.owner.choosenTokenId && !user.owner.discord_userName) navigate("/discord");
        if(isAuth && user.owner.choosenTokenId && user.owner.discord_userName) navigate(from)
    
    }, [isAuth, navigate]);

    
    return(
        <Overlay>
            <PopupContainer>
                <Title>Loading...</Title>
            </PopupContainer>
        </Overlay>

    )

}

export default Loader