import React from 'react'
import styled,{keyframes, css} from 'styled-components'
import Vector2 from "../Assets/Vector2.png"
import { Waypoint } from 'react-waypoint';
import { useState } from 'react';
import Gif from "../Assets/gifgif.gif"
import Discord from "../Assets/DiscordLogo.svg"

const EnterLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-40%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
`

const EnterRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(40%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
`
const HistoryContainerplus = styled.div`
display:flex;
flex-direction:column;
height:200vh;
justify-content:space-between;
gap:70px;
@media (max-width: 950px) {
 
  height:230vh;
  gap:100px;
}
@media (max-width: 325px) {
 
  height:370vh;
  gap:100px;
}
`

const HistoryContainer = styled.div`
    width:100%;
    height:80vh;
    
    display:flex;
    align-items:flex-start;
    align-self: start;
    padding-top:8%;
    gap:20px;
    jusitfy-content:space-between;
    flex-direction:row;
    @media (max-width: 950px) {
        flex-direction:column;
        height:120vh;
        margin-top:10%;
    }
    
    @media (max-width: 375px) {
      margin-top:20%;
    }  
    @media (max-width: 325px) {
        height:180vh;
    }  
    @media (device-width: 412px) and (device-height: 915px){
              height: 100vh;
    }     
    @media (device-width: 375px) and (device-height: 667px){
      margin-top:0%;
      height: 135vh;
    }     
    @media (device-width: 320px) and (device-height: 658px){
      height: 130vh;
             }
    @media  (device-width: 768px) and (device-height: 1024px)  {
        height: 50vh;
        margin-top:-5%;
                                                              }   
  @media (device-width: 950px) and (device-height: 1180px)  {
        height: 40vh;
        margin-top:0%;
                     }  
 @media only screen and (min-width: 1100px) and (max-width: 1390px) {    
        height:110vh;
        padding-top:0%;
     
                    }   
                    @media only screen and (min-width: 901px) and (max-width: 1100px) {    
                      height:120vh;
                      padding-top:0%;
                    
                  
                                  }  
                                   
                                     
                                                           
                                
                                
` 



const TitleHistory = styled.h1`
    width:70%;
    height:70vh;

    text-align:flex-start;
    align-self:start;
    align-items:flex-start;
    color:white;
    font-size:3.5rem;
    text-shadow:  rgba(255,255,255,0.9) 0px 0px 6px; 
    span {
        color: #02ff79; 
        white-space: normal;
        text-shadow:  #02ff79 0px 0px 6px; 
    
      }
    @media (max-width: 950px) {
        width:80%;
        color:white;
        margin-left:0%;
        height:50vh;
     
        font-size:2.2rem;
        line-height:1em;
        display:block;
        text-align:center;
        align-items:center;
      
      }
      @media (max-width: 375px) {
        height:50vh;
        font-size:2rem;
        margin-top:-10%;
            }  
             
            
  
`
const TextHistory = styled.p`
    width:90%;
   
    color:white;
    font-size:0.9rem;
    line-height:1.7em;
    //text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
    @media (max-width: 950px) {
      font-size:0.9rem; 
      line-height:1.6em;
      width:99%;
    }
    @media (max-width: 325px) {
      font-size:0.8rem;
          }  
               
         
    `
const ButtonHistory = styled.button`
    border:none;
    color:#02ff79;
    background-color:transparent;
    font-size:0.9rem;
    text-align: left;
    font-weight:600;
    @media (max-width: 950px) {
    }
`
const ButtonAndText = styled.div<{ isVisible: boolean }>`
    display:flex;
    flex-direction:column;
    height:100%;

    justify-content:flex-start;
    width:50%;
    align-items:flex-start;
    align-self:start;
    opacity: 0;
    animation: ${({ isVisible }) => isVisible ? css`${EnterRight} 1s 0.5s cubic-bezier(0.77, 0, 0.175, 1) forwards` : 'none'};
    @media (max-width: 950px) {
        width:85%;
        height:48%;
        justify-content:center;
        margin-top:-22%; 
        align-items:center;
        align-self:center;
    }
    @media (device-width: 375px) and (device-height: 667px){
      margin-top:10%;
    } 
   
    @media (max-width: 325px) {
        margin-top:20%;
    }  
    @media (device-width: 414px) and (device-height: 896px){
        margin-top:-40%;
    } 
    @media (device-width: 412px) and (device-height: 915px){
      margin-top:-30%;
    }  
    @media (device-width: 414px) and (device-height: 736px){
    margin-top:-10%;
    }  
    @media (device-width: 320px) and (device-height: 658px){
      margin-top:-5%;
             }
    @media (device-width: 360px) and (device-height: 740px){
      margin-top:-5%;
    }   
    @media only screen and (device-width: 768px) and (device-height: 1024px)  {
      margin-top:-30%;
      justify-content:center;
      align-items:center;
      align-self:center;
    }    
    @media only screen and (device-width: 950px) and (device-height: 1180px)  {
      margin-top:-40%;
      justify-content:center;
      align-items:center;
      align-self:center;
    }  
                                     
` 
const TitleContainer = styled.div<{ isVisible: boolean }>`
    display:flex;
    line-height:3.4em;
    background-image: url(${Vector2});
    background-size: cover;
    background-repeat: no-repeat;
    width:50%;
    justify-content: center;
    height:70vh;
    opacity: 0;
    transform: translateX(-100%);
    animation: ${({ isVisible }) => isVisible ? css`${EnterLeft} 1s cubic-bezier(0.77, 0, 0.175, 1) forwards` : 'none'};
    @media (max-width: 950px) {
        width:100%;
        margin-left: 0%;
        height: auto;
    }
    
` 

const QuestionContainer = styled.div`
    width:100%;
    height:70vh;
    display:flex;
    padding-top:2%;
    align-items:center;
    jusitfy-content:center;
    flex-direction:column;
  
   
                       
                                                     
    
` 
const GifImage = styled.img`
    width: 20%;
    height: auto;
    margin-top:-5%;
    @media (max-width: 950px) {
        width: 60%;
     }
     @media only screen and (device-width: 768px) and (device-height: 1024px)  {
        width: 40%;
                   }   
                                   
`;

const TitleQuestion = styled.h1`
    text-align:center;
    width:40%;
    line-height:1.1em;
    justify-content:center;
    align-items:center;
    font-size: 3rem;
    color: white;
    text-shadow:  rgba(255,255,255,0.9) 0px 0px 6px; 
    @media (max-width: 950px) {
        font-size: 2.2rem;  
        width:85%;
        line-height:1em;

    }

        `;

const TextQuestion = styled.p`
    width:40%;
    color:white;
    font-size:0.9rem;
    text-align:center;
    line-height:1.7em;
    //text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
    @media (max-width: 950px) {
        font-size:0.9rem;
        width:85%;
        display:flex;
        justify-content:center;
        align-items:center;
        text-align:center;
    }
    @media (max-width: 325px) {
        font-size:0.8rem;
               }
    `   
const ButtonQuestion = styled.button`
    margin-top:2%;
    font-weight:600;
    border-radius: 20px;
    font-size:0.72rem;
    font-family: 'Space Grotesk', sans-serif;
    padding: 15px 25px 15px 25px ;
    background-color:#02ff79;
    color: black;
    text-align: center;
    cursor:pointer;
    border:none;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    &:hover{
      background-color: #fff; 
      box-shadow: 0 0 10px #02ff79; 
    }
    @media (max-width: 950px) {
       transform:scale(0.9);
       border-radius: 25px;
       margin-top:5%;
       padding: 15px 55px 15px 55px ;
       font-size:1rem;
    }
`
const ShakeAnimation = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;


const JoinUsPageContainer = styled.div`
  
    width:100%;
    height:15vh;
    display:flex;
    align-items:center;
    justify-content:center;
    @media (max-width: 950px) {
      height:35vh;
    }
                                              
` 
const JoinUsContainer = styled.div<{isInView: boolean}>`
    width:65%;
    height:20vh;
    border: 2px solid #02ff79;
    border-radius: 5px; 
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px; 
    border-bottom-left-radius: 10px; 
    border-bottom-right-radius: 10px; 
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-around;
    animation: ${props => props.isInView ? css`${ShakeAnimation} 0.82s cubic-bezier(.36,.07,.19,.97) both` : 'none'};
    @media (max-width: 950px) {
        width:88%;
        height:25vh;
        flex-direction:column;
        justify-content:center;
        padding:2%;
        border-top-left-radius: 15px; 
        border-top-right-radius: 15px; 
        border-bottom-left-radius: 15px; 
        border-bottom-right-radius: 15px; 
    }
    @media (max-width: 325px) {
        height:40vh;
    }
    @media (device-width: 375px) and (device-height: 667px){
        width:80%;
        padding:4%;
    } 
   @media only screen and (device-width: 768px) and (device-height: 1024px)  {
       width:70%;
    }          
` 
const DiscordImg = styled.img`
    
    @media (max-width: 950px) {   

    }
    @media (max-width: 325px) {
     
    }
    @media (device-width: 375px) and (device-height: 667px){
    
     
    } 
   @media only screen and (device-width: 768px) and (device-height: 1024px)  {
    
    }           
`
const DiscordText = styled.h1`
    width:100%;
    color:white;
    margin-left:5%;
    text-shadow:0.1px 0.1px 0.1px #fff, 0.1px 0.1px 5px #ccc;
    font-size: 2.5rem;
    line-height:1em;
    @media (max-width: 950px) {
        font-size: 1.7rem;
        width:45%;
        margin-right:10%;
        line-height:0.9em;
        margin-left:0%;
    }
`
const ButtonCall = styled.button`
    height:9%;
    width:15%;
    font-weight:600;
    border-radius: 25px;
    padding: 20px;
    background-color:#02ff79;
    align-items:center;
    justify-content:center;
    display:flex;
    color: black;
    font-weight:600;
    font-size:0.76rem;
    cursor:pointer;
    border:none;
    box-shadow: 0 0 5px #007bff; 
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    &:hover{
      background-color: #fff; 
      box-shadow: 0 0 10px #02ff79; 
    }
    @media (max-width: 950px) {
        width:65%;
        font-size:0.9rem;
    }
    
`
const TextAndLogo = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    width:60%;
    flex-direction:row;
    @media (max-width: 950px) {
        width:100%;
        justify-content:center;  
    }
    @media only screen and (device-width: 768px) and (device-height: 1024px)  {
        margin-left:10%;
    }  
`

const History = () => {
  const [isVisibleTitleContainer, setIsVisibleTitleContainer] = useState(false);
  const [isVisibleButtonAndText, setIsVisibleButtonAndText] = useState(false);

  const onEnterTitleContainer = () => {
      setIsVisibleTitleContainer(true);
  };

  const onEnterButtonAndText = () => {
      setIsVisibleButtonAndText(true);
  };

  const [isInView, setIsInView] = useState(false);
  const onEnterViewport = () => {
      setIsInView(true);
  };
  const handleOpenDiscord = () =>{
      window.open('https://discord.com/invite/undw3', '_blank', 'noopener,noreferrer')
  }

  return (
    <HistoryContainerplus>
   <HistoryContainer>
      <Waypoint onEnter={onEnterTitleContainer}>
        <TitleContainer isVisible={isVisibleTitleContainer}>
              <TitleHistory>GET INVOLVED AND BECOME A FULL MEMBER OF THE <span>LACOSTE</span> &nbsp; COMMUNITY</TitleHistory>
        </TitleContainer>
      </Waypoint>
      <Waypoint onEnter={onEnterButtonAndText}>
        <ButtonAndText isVisible={isVisibleButtonAndText}>
            <TextHistory>
            The mission is the result of a collaboration between the UNDW3 community and the brand's innovation strategy. It is a new and innovative type of interactive experience centered around the UNDW3 project and the Genesis Pass NFT.
            </TextHistory>
            <TextHistory>
            The holders of the Lacoste Genesis Pass NFT will have the mission to solve quests, engage with the brand, and unlock exclusive benefits that will only be accessible to Genesis Pass holders. Every week, raffles will be organized for all community members, with the opportunity to win exclusive rewards (merchandise, digital twins, etc.)
            </TextHistory>
            <TextHistory>
            It is an innovative product with groundbreaking technical developments. The goal is to evolve "The Mission" by actively involving the community's feedback. The aim is to co-create the brand of tomorrow with the web3 community.
            </TextHistory>
            <TextHistory>
            During this "Mission," the user's engagement will allow their NFT to evolve graphically and transform into a Lacoste access card. The more a member engages with the brand, the more they become a full member of the Lacoste community and have decision-making power in the future of the brand.
            </TextHistory>
            <ButtonHistory>Get ready crocodiles.</ButtonHistory>
        </ButtonAndText>
      </Waypoint>
        
   </HistoryContainer>
    <QuestionContainer>
    <GifImage src={Gif} alt="GIF"/>
    <TitleQuestion>DON'T HAVE YOUR GENESIS YET ?</TitleQuestion>
    <TextQuestion>
    To participate in the The Mission and progress in the community,
you must have an NFT Genesis pass.
    </TextQuestion>
    <ButtonQuestion>GET YOUR GENESIS NOW</ButtonQuestion>
</QuestionContainer>
<JoinUsPageContainer>
        <Waypoint onEnter={onEnterViewport}>
            <JoinUsContainer isInView={isInView}>
            <TextAndLogo>
                <DiscordImg src={Discord}/>
                <DiscordText>JOIN THE MISSION ON DISCORD</DiscordText>
            </TextAndLogo>
            <ButtonCall onClick={handleOpenDiscord}>JOIN US</ButtonCall>
        </JoinUsContainer>
        </Waypoint>
        
    </JoinUsPageContainer>
</HistoryContainerplus>
  )
}

export default History