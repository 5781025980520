import React from 'react'
import styled from 'styled-components'
import MyCollection from './MyCollection'
import Score from './Score'
import MyGenesis from './MyGenesis'
import Mission from './Mission'

const ElementContainer = styled.div`
    display: flex;
    flex-direction:column;
    width:80%;
    justify-content:center;
    margin:0 auto;
    padding:0;
    margin-top:5%;
    height:100%;
    
`
const ScoreAndCollection = styled.div`
    display:flex;
    flex-direction:row;
    @media (max-width: 820px) {
        flex-direction:column-reverse;
    }

`
const GenesisAndMission = styled.div`
    display:flex;
    margin-top:10%;
    flex-direction:row;
    @media (max-width: 820px) {
        flex-direction:column;
    }
`

const FourElements = () => {
  return (
    <ElementContainer>
        <ScoreAndCollection>
            <MyCollection/>
            <Score/>
        </ScoreAndCollection>
        <GenesisAndMission>
            <MyGenesis/>
            <Mission/>
        </GenesisAndMission>
    </ElementContainer>
  )
}

export default FourElements