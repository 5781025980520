import React from 'react';
import styled, {keyframes, css} from 'styled-components';

interface Props {
  score: number;
  total: number;
}


const grow = (width: number) => keyframes`
  0% {
    width: 0;
  }
  100% {
    width: ${width}%;
  }
`;

const ProgressBarContainer = styled.div`
  background-color: #1e1e1e;
  height: 6vh;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  width: 75%;
  @media (max-width: 820px) {
    width:100%;
  }
`;

const ProgressBar = styled.div<{ width: number }>`
  background-color: #02ff79;
  height: 100%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  width: ${({ width }) => `${width}%`};
  box-shadow: 0 0 10px #02ff79;
  animation: ${({ width }) => css`${grow(width)} 2s ease-in-out`};
  animation-fill-mode: forwards;
`;

const ProgressText = styled.div`
  align-items:center;
  height:6vh;
  padding-left:5%;
  justify-content:flex-start;
  display:flex;
  color:black;


`;

const ProgressScore = styled.span`
color:black;
font-weight: bold;
font-size:0.7rem;
`;

const ProgressTotal = styled.span`
font-weight: bold;
  color:black;
  font-size:0.7rem;
`;

const Progress: React.FC<Props> = ({ score, total }) => {
  const progress = (score / total) * 100;

  return (
    <>
      <ProgressBarContainer>
        <ProgressBar width={progress}>
             <ProgressText>
                <ProgressScore>{score}&nbsp;</ProgressScore> /{' '}
                <ProgressTotal>&nbsp;{total}</ProgressTotal>
            </ProgressText>
        </ProgressBar>
      </ProgressBarContainer>
     
    </>
  );
};

export default Progress;
