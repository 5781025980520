import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(10px);
  background: black;
  z-index: 9999;

`;

const PopupContainer = styled.div`
    position: fixed;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px; 
    border-bottom-left-radius: 15px; 
    border-bottom-right-radius: 15px; 
    top: 50%;
    left: 50%;
    width:30%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    padding-bottom: 2rem;
    padding-top: 2rem;
    z-index: 101;
    @media (max-width: 800px) {
        width:90%;
        height:60vh;
    }
`;
const Title = styled.h1`
    width:80%;
    color:white;
    align-items:center;
    justify-content:center;
    display:flex;
    text-align:center;
    font-size:1.2rem;
    @media (max-width: 800px) {
        font-size:1.2rem;
        width:95%;
       
    }
`



const LoaderHome:React.FC<{title: string}> = (props) => {
    return(
        <Overlay>
            <PopupContainer>
                <Title>{props.title}</Title>
            </PopupContainer>
        </Overlay>

    )

}

export default LoaderHome