import React from 'react';
import styled from 'styled-components';
import Trophe from "../Assets/OverlayTrophe.png"
import { useSelector } from 'react-redux';

interface WinnersProps {
  discordId: string,
  wallet: string,
  currentTokenImage: string,
  _id: string,
}

interface LittleCardsProps {
  winners: WinnersProps[],
  isWinner: boolean,
}
type CardType = {
  title: string;
  imageSrc: string;
  isGood: boolean;
  text: string;
}

const cardsData: CardType[] = [
  { title: "ID DISCORD", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, text: "YOU WON !" },
  { title: "ID DISCORD", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: true, text: "YOU WON !" },
  { title: "ID DISCORD", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, text: "YOU WON !" },
  { title: "ID DISCORD", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, text: "YOU WON !" },
  { title: "ID DISCORD", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: true, text: "YOU WON !" }
];

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height:42vh;
  padding:10px;
  margin-top:-3%;
`;

const Card = styled.div<{ isGood: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 6vh;
  margin:3px;
  
  padding: 10px;
  color : ${({ isGood }) => isGood ? 'black' : 'white'};
  border-radius: 10px;
 
  border : 0.1px solid #404040;
  background-color: ${({ isGood }) => isGood ? '#02ff79' : 'transparent'};
  box-shadow: ${({ isGood }) => isGood ? "0 0 10px #02ff79" : '0px 2px 4px rgba(0, 0, 0, 0.25)'};
`;



const CardImage = styled.img`
  width: 27%;
  height: 27%;
  border-top-left-radius: 15px; 
  border-top-right-radius: 15px; 
  border-bottom-left-radius: 15px; 
  border-bottom-right-radius: 15px; 
  @media (max-width: 820px) {
    width: 27%;
  height: 27%;
  }
  @media (max-width: 325px) {
    border-top-left-radius: 10px; 
  border-top-right-radius: 10px; 
  border-bottom-left-radius: 10px; 
  border-bottom-right-radius: 10px; 

  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    width: 18%;
    height: 18%;
         } 
`;

const OverlayImage = styled.img`
  position: absolute;
 transform:scale(0.6);
  width: 3.8%;
  height: 6%;
  border-top-left-radius: 15px; 
  border-top-right-radius: 15px; 
  border-bottom-left-radius: 15px; 
  border-bottom-right-radius: 15px; 
  z-index:99;
  @media (max-width: 820px) {
    width: 10%;
  height: 7%;
margin-left:2%;
  }
  @media (max-width: 325px) {
    border-top-left-radius: 10px; 
  border-top-right-radius: 10px; 
  border-bottom-left-radius: 10px; 
  border-bottom-right-radius: 10px; 
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    margin-left:-0.5%;
         } 
`;

const CardTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  padding:10px;
  justify-content:flex-start;
  display:flex;

  width:90%;
`;

const TextImg = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  width:50%;
  justify-content:space-between;
  @media (max-width: 820px) {
    width: 70%;
    
  
  }
`;
const ContainerCardText = styled.div`
width: 20%;
height: 6vh;
display: flex;
justify-content: flex-start;
align-items: start;
@media (max-width: 820px) {
  width: 25%;
}
@media (max-width: 320px) {
  width: 28%;
}
`;

const CardText = styled.div`
  width: 100%;
  height: 2vh;
  border-top-left-radius: 15px; 
  border-top-right-radius: 15px; 
  border-bottom-left-radius: 15px; 
  border-bottom-right-radius: 15px; 
  background-color: #000;
  font-size:0.7rem;
  font-weight:bold;
  text-shadow:0 0 10px #02ff79;
  color:transparent;
  color: #02ff79;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 320px) {
    height: 3vh;
  }
`;

const LittleCards:React.FC<LittleCardsProps> = ({winners, isWinner}) => {
  const {user} = useSelector((state: any) => state.auth)
  return (
    <CardsContainer>
      {winners!.map((elem, index) => (
        <Card key={index} isGood={elem.discordId === user.owner.discord_userName && isWinner}>
          <TextImg> 

          {elem.discordId === user.owner.discord_userName && isWinner && <OverlayImage src={Trophe}/>}
            <CardImage src={elem.currentTokenImage} />

            <CardTitle>{elem.discordId.toLocaleUpperCase()}</CardTitle>
           
          </TextImg>
          <ContainerCardText>
          {elem.discordId === user.owner.discord_userName && isWinner && <CardText>YOU WON !</CardText>}
          </ContainerCardText>
        </Card>
      ))}
    </CardsContainer>
  );
};

export default LittleCards;