import React, { useState } from 'react';
import styled from 'styled-components';
import RankOn from './RankOn';
import RankingOff from './RankingOff';
import V from "../Assets/V.png"

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom:15%;
    width:100%;
    flex-direction:column;
    @media (max-width: 820px) {
        margin-bottom:20%;
        
           }
           @media (max-width: 325px) {
            margin-bottom:15%;
            
               }  
               @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
                margin-bottom:10%;
                     }                 

`;
const ButtonConainer = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    height:5vh;
    flex-direction:row;
    width:100%;
    

`;
const ShowContainer = styled.div`
    display: flex;
    justify-content: center;
    height:5vh;
    width:10%;
    align-items:center;
    @media (max-width: 820px) {
        width:30%;
           }
                 
           @media (max-width: 325px) {
            width:35%;
               }

`;
const ShowTxt = styled.h1`
    color:white;
    font-size:0.9rem;
    margin-right:-20%;
    @media (max-width: 325px) {
        padding-left:1px;
              }   
`;

const RightButton = styled.div`
    border: none;
    background-color: #1e1e1e;
    color: white;
    height:auto;
    padding: 16px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    cursor: pointer;
    position: relative;

`;

const InnerRound = styled.button<{ active: boolean }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    border:none;
    height: 80%;
    border-radius: 50%;
    background-color: ${(props) => (props.active ? '#02ff79' : 'transparent')};
    transition: all 0.2s ease-in-out;
`
const LeftButton = styled.button`
    position: relative;
    border: none;
    background-color: #1e1e1e;
    color: white;
    padding:  16px;
    display: flex;
    height: auto;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    cursor: pointer;
`;

const InnerRound2 = styled.div<{ active: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  border:none;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? 'none' : '#8f8f8f')};
  transition: all 0.2s ease-in-out;
`;

const Text = styled.div<{ active: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  font-weight:700;
  background-color:transparent;
  transform: translate(-50%, -50%);
  color: black;
  opacity: ${(props) => (props.active ? '1' : '0')};
`;
const TitleResults = styled.h1`
    font-size:4.5rem;
    display:flex;
    align-items:center;
    justify-content:center;
    color:white;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
`

const IconV= styled.img `
    width:90%;
    height: 100%;
`

const CustomButton = () => {
    const [isActive, setIsActive] = useState(false);
    
  
    const handleButtonClick = () => {
      setIsActive(!isActive);
    };
  
    
  
    return (
        <>
      
      <ButtonWrapper>
        <ButtonConainer>
            <LeftButton onClick={handleButtonClick}>
                <InnerRound2 active={isActive} />
            </LeftButton>
            <RightButton onClick={handleButtonClick}>
                <InnerRound active={isActive} />
                <Text active={isActive} > <IconV src={V} /></Text>
            </RightButton>
            <ShowContainer>
                <ShowTxt>
                    SHOW ME TOP 10
                </ShowTxt>
            </ShowContainer>
        </ButtonConainer>
             {isActive ? <RankOn/> : <RankingOff/>}
           
      </ButtonWrapper>
       
        </>
    );
  };

  export default CustomButton