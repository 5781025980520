import { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import GenNFTService from "../services/fetchGenesis";

interface CardProps {
  tokenId: string;
  imageUrl: string;
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
background:red;
  width:75%;
  @media (max-width: 820px) {
    display: flex;
    width:150%;
  margin-right:15px;
    flex-wrap: nowrap;
 
       }
 
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  width: 14%;
  @media (max-width: 820px) {

    flex: 0 0 auto;
    width: 150px; /* replace with your card width */
    margin: 10px;
  
 
       }
  
`;

const Image = styled.img`
  width: 150px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content:flex-start;
  width:100px;
  @media (max-width: 820px) {

    width:100%;
  
 
       }
`;

const Title = styled.p`
  font-size: 0.8rem;
  margin-top: 10px;

  color:white;
  opacity:0.9;
`;


// const cardsData: CardProps[] = [
//   {
//     token: "456278",
//     image: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//   },
//   {
//     token: "456278",
//     image: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//   },
//   {
//     token: "456278",
//     image: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//   },
//   {
//     token: "456278",
//     image: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//   },
  
//   {
//     token: "456278",
//     image: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//   },
//   {
//     token: "456278",
//     image: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//   },
//   {
//     token: "456278",
//     image: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//   },
//   {
//     token: "456278",
//     image: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//   },
//   {
//     token: "456278",
//     image: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//   },
//   {
//     token: "456278",
//     image: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//   },
//   {
//     token: "456278",
//     image: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//   },
//   {
//     token: "456278",
//     image: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2",
//   },
// ];

const Loader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
`

const CardToken = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [ownerNft, setOwnerNft] = useState<CardProps[]>([])
  const {user, isAuth} = useSelector((state:any) => state.auth)

  
  const handleFetchNft = async() =>{
    setIsLoading(true)
    const response = await GenNFTService.getGenesisNFTs(user.owner.wallet, user.owner._id)
    if(!response) return;
    setOwnerNft(response.metadata)
    setIsLoading(false)

  }

  useEffect(()=> {
    handleFetchNft()
    console.log(ownerNft)
  },[])
  return (
<>
      {isLoading && <Loader>Getting your NFTs...</Loader>}
      {!isLoading &&  ownerNft.map((elem) => (
        <Card key={elem.tokenId}>
          <Image src={elem.imageUrl} />
          <TitleContainer>
            <Title>Token ID : {elem.tokenId}</Title>
          </TitleContainer>
        </Card>
      ))}
   </>
  );
};

export default CardToken;