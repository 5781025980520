import React from 'react'
import styled from 'styled-components'
import Icons from "../Assets/Logo.png"
import Vector from "../Assets/Vector2.png"
import Vector2 from "../Assets/VecFoot.png"

const FooterContainerImg = styled.div`
  width:100%;
  height:20vh;
  flex-direction:row;
  display:flex;
  gap:2%;
  align-items:center;
  positon:absolute;
  bottom:0;
  justify-content:center;
  @media (max-width: 820px) {
    background-image: url(${Vector});
    background-size:cover;
  
    background-repeat: no-repeat;
    height:15vh;
    }
  @media (max-width: 800px) {
   background-image: url(${Vector2});
   background-size:cover;
 
   background-repeat: no-repeat;
   height:30vh;
   }
   @media (max-width: 375px) {
    height:40vh;
    }
   @media (max-width: 325px) {
    height:50vh;
    }

    @media (device-width: 768px) and (device-height: 1024px){    
      background-image: url(${Vector});
      background-size:cover;
      height:15vh;
      }
  
`
const FooterContainer = styled.div`
  margin-top:5%;
  width:100%;
  height:6vh;
  background:transparent;
  flex-direction:row;
  display:flex;
  gap:2%;
  align-items:center;
  justify-content:center;
  @media (max-width: 767px) {
    height:16vh;
    flex-direction:column;
    justify-content:space-between;
    }
`
const HowItWorkButton =styled.button`
  border:none;
  background-color:transparent;
  color:white;
  font-size:0.7rem;
  font-weight:600;

`
const LacosteText = styled.p`
  color:white;
  font-size:0.7rem;
  font-weight:600;
`
const LacosteText2 = styled.p`
  color:white;
  font-size:0.7rem;
  font-weight:600;
`
const LacosteText3 = styled.p`
  color:white;
  font-size:0.7rem;
  font-weight:600;
`
const LacosteMentionLegal = styled.p`
  color:white;
  font-size:0.7rem;
  display:flex;
  align-items:center;

  @media (max-width: 767px) {
    
    justify-content:center;
      }
`
const Point = styled.div`
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
  @media (max-width: 767px) {
    
  display:none;
    }
`
const Icon = styled.img`
  width:12%;
 

`
const FooterNotHome = () => {
  return (
    <FooterContainerImg>
    <FooterContainer>
      <HowItWorkButton>How it works ?</HowItWorkButton>
        <Point/>
      <LacosteText>Lacoste UNDW3</LacosteText>
        <Point/>
      <LacosteText2>Terms of sales</LacosteText2>
        <Point/>
      <LacosteText3>Legal Notice</LacosteText3>
        <Point/>
        <LacosteMentionLegal>
        <Icon src ={Icons}/>  &nbsp;  Lacoste© All Rights reserved</LacosteMentionLegal>
    </FooterContainer>
    </FooterContainerImg>
  )
}

export default FooterNotHome