import React, { useState } from "react";
import styled from "styled-components";

const ContainerButton = styled.div`
  display:flex;
  flex-direction:row;
  transform:scale(0.8);
`
const LanguageButton = styled.button<{ isSelected: boolean }>`
  background-color: transparent;
  border: none;
  text-decoration: ${(props) => (props.isSelected ? "underline" : "none")};
  text-decoration-color: ${(props) => (props.isSelected ? "#02ff79" : "white")};
  font-weight: ${(props) => (props.isSelected ? "bold" : "normal")};
  text-underline-offset: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Space Grotesk', sans-serif;
  color: white;
  
  &:hover {
    text-underline-offset: 10px;
  }
`;


const LanguagesButton = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>("fr");

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
  };

  return (
    <ContainerButton>
      <LanguageButton isSelected={selectedLanguage === "fr"} onClick={() => handleLanguageChange("fr")}>
        FR
      </LanguageButton>
          <p>/</p>
      <LanguageButton isSelected={selectedLanguage === "en"} onClick={() => handleLanguageChange("en")}>
        EN
      </LanguageButton>
    </ContainerButton>
  );
};

export default LanguagesButton;