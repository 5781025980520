import React from 'react'
import styled from 'styled-components';
import {useState} from 'react';
import FooterNotHome from '../Footer/FooterNotHome'

type CardType = {
    num: string;
    id :string;
    imageSrc: string;
    isGood: boolean;
    points: string;
  }
  
  const cardsData: CardType[] = [
    { num: "#4", id: "ID DISCORD ", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3452" },
    { num: "#5", id: "ID DISCORD ", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3451" },
    { num: "#6", id: "ID DISCORD ", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3452" },
    { num: "#7", id: "ID DISCORD ", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3452" },
    { num: "#8", id: "ID DISCORD ", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3452" },
    { num: "#9", id: "ID DISCORD ", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3452" },
    { num: "#10", id: "ID DISCORD ", imageSrc: "https://ipfs.io/ipfs/QmWPBAkG5bZ5ZDNPy4XqK5BrzhgzHqCSeQALRWggKqmpR2", isGood: false, points: "3452" },
  ];
  
  const CardsContainer = styled.div`
    display: flex;

    flex-direction: column;
    align-items: center;
    width: 100%;
    height:60vh;
    padding:10px;
    margin-top:12%;
    @media (max-width: 325px) {
      padding:1px;

        } 
        @media (max-width: 820px) {
          padding:0px;
    
            }     
   
  `;
  
  const CardsAll = styled.div`
    margin-top:-10%;
    align-items: center;
    flex-direction: column;
    display:flex;
    width: 60%;

    @media (max-width: 820px) {
      width: 100%;
    } 
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
      width: 90%;
     
                   } 
  `;
  const Card = styled.div<{ isGood: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 6vh;
    margin:5px;
    padding: 10px;
    color : ${({ isGood }) => isGood ? 'black' : 'white'};
    border-radius: 10px;
    border : none;
    background-color: ${({ isGood }) => isGood ? '#02ff79' : '#1e1e1e'};
    box-shadow: ${({ isGood }) => isGood ? "0 0 10px #02ff79" : '0px 2px 4px rgba(0, 0, 0, 0.25)'};
  `;
  
  
  
  const CardImage = styled.img`
    width: 20%;
    height: 20%;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px; 
    border-bottom-left-radius: 15px; 
    border-bottom-right-radius: 15px; 
    @media (max-width: 820px) {
      width: 25%;
      height: 25%;
      border-top-left-radius: 10px; 
      border-top-right-radius: 10px; 
      border-bottom-left-radius: 10px; 
      border-bottom-right-radius: 10px; 
         } 
         @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
          width: 18%;
          height: 18%;
                       }        
  `;
  

  
  const CardTitle = styled.h3`
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @media (max-width: 325px) {
      font-size: 0.9rem;
         } 
  `;
  
  const CardNumber = styled.h3`
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    @media (max-width: 325px) {
      font-size: 1.2rem;
         } 
  `;

  const TextImg = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    width:40%;
    justify-content:space-between;
    @media (max-width: 820px) {
      width:60%;
      
         }       

  `;
  const ContainerCardText = styled.div`
  width: 20%;
  height: 6vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 820px) {
    width: 28%;
    
       } 
       @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
        width: 15%;
    
        
     
                     }      
  `;
  
  const CardText = styled.div`
    width: 100%;
    height: 7vh;
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px; 
    border-bottom-left-radius: 10px; 
    border-bottom-right-radius: 10px; 
    background-color: #000;
    font-size:2rem;
    font-weight:bold;
    color:transparent;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 820px) {
      font-size:1.6rem;
      
         }  
    @media (max-width: 325px) {
      font-size: 1.2rem;
             }  
   @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
      font-size:1.2rem;
                           }                
  `;
  
  const CardPoint = styled.h3`
  font-size: 0.7rem;
  font-weight: bold;
  display:flex;
  align-self:start;

  
`;
 



const ListPodium = () => {

  return (
    <CardsContainer>
        <CardsAll>
         
    {cardsData.map((card, index) => (
        
      <Card key={index} isGood={card.isGood}>
        <TextImg> 
          <CardImage src={card.imageSrc} />
            <CardNumber>{card.num} </CardNumber>
          <CardTitle>{card.id}</CardTitle>
        </TextImg>
        <ContainerCardText>
      <CardText>{card.points} 
        <CardPoint>
        PTS
       </CardPoint>
      </CardText>
        </ContainerCardText>
      </Card>
      
    ))}
  
    </CardsAll>
    <FooterNotHome/>
  </CardsContainer>
);
};



export default ListPodium