import React, { useState, useEffect }  from 'react'
import styled from 'styled-components'
import Basket from "../Assets/Basket.png"
import LittleCards from './LittleCard'
import FooterNotHome from '../Footer/FooterNotHome'
import Elispe from "../Assets/Ellipse.png"
import leftIconInactive from "../Assets/WeekIcon1.png"
import leftIconActive from "../Assets/WeekIcon1bis.svg"
import rightIconActive from "../Assets/WeekIcon2.png"
import rightIconInactive from "../Assets/WeekIcon2bis.svg"
import { useSelector } from 'react-redux'
import raffleService from '../services/raffles.service'

interface Winners {
  discordId: string,
  wallet: string,
  currentTokenImage: string,
  _id: string,
}

interface RaffleProps {
  raffleDateRange: string,
  name: string,
  winners: Winners[],
  imagePath: string,
}

const WeekContainer= styled.div`
  display:flex;
  align-items:center;

  height:100%;
  width:100%;
  justify-content:center;
  flex-direction:row;
  @media (max-width: 820px) {
    flex-direction:column;
  }
  @media (max-width: 325px) {
        height:125vh;
  }    
  @media (device-width: 375px) and (device-height: 812px)  {
    height:100vh;
  }                  
`
const Week = styled.div`
  display:flex;
  align-items:center;
  height:55vh;
  width:70%;
  justify-content:space-between;
  border: none;
  background-color:#1e1e1e;
  border-top-left-radius: 15px; 
  border-top-right-radius: 15px; 
  border-bottom-left-radius: 15px; 
  border-bottom-right-radius: 15px; 
  padding: 15px;
  @media (max-width: 820px) {
    flex-direction:column;
    height:auto;
    width:80%;
  }
  @media (max-width: 325px) {
    height:160vh;
  }         
`
const ContainerOffer = styled.div`
  display:flex;
  align-items:center;
  flex-direction:column;
  justify-content:flex-start;
  width:50%;
  height:55vh;
  @media (max-width: 820px) {
    width:100%;
  }
`
const SubTitle = styled.h4`
  font-size:1rem;
  display:flex;
  align-items:center;
  color:#02ff79;
  @media (max-width: 820px) {
    font-size:1.5rem;
  }
` 
const Title = styled.h1`
  color:#02ff79;
  font-size:2.5rem;
  align-items:center;
  display:flex;
  margin-top:-5%;
  @media (max-width: 820px) {
    font-size:1.7rem;
    margin-top:-15%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    margin-top:-5%;
  }      
`
const TextPrize = styled.div`
  display:flex;
  align-items:center;
  flex-direction:column;
  justify-content:flex-start;
  width:80%;
  text-align:center;
  height:50vh;
  @media (max-width: 820px) {
    width:80%;
    margin-left:30%;
  }
  @media (max-width: 820px) {
    margin-left:10%;
    margin-top:-15%;
    transform:scale(0.8);
  }   
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    margin-top:-5%;
    margin-left:45%;             
  }             
`

const List = styled.div`
  display:flex;
  align-items:center;
  width:45%;
  height:55vh;
  justify-content:center;
  flex-direction:column;
  border: none;
  background-color:transparent;
  border-top-left-radius: 15px; 
  border-top-right-radius: 15px; 
  border-bottom-left-radius: 15px; 
  border-bottom-right-radius: 15px; 
  padding: 5px;
  @media (max-width: 820px) {
   width:100%;
   margin-top:-40%;
  }
  
`
const ContainerImg = styled.div`
  display:flex;
  align-items:center;
  background-image: url(${Elispe});
  background-size: contain;
  background-repeat: no-repeat;
  justify-content:center;
  height:80vh;
  margin-top:-30%;
  width:70%;
  justify-content:center;
  position:relative;
  @media (max-width: 820px) {
    width:100%;
    height:80vh;
    margin-top:-75%;
    background-size: contain;
  }
  @media (max-width: 325px) {
    margin-top:-55%;
  } 
  @media (max-width: 375px) {
    margin-top:-50%;
  } 
  @media (device-width: 375px) and (device-height: 812px)  {
    margin-top:-80%;
  } 
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
      transform:scale(0.7);
      margin-top:-90%;
  }                                       

`
const ImgBasket = styled.img`
  width:100%;
  height:67%;
  z-index:1;
  margin-top:10%;
  transform:scale(1.2);
  @media (max-width: 820px) {
    width:100%;
    height:50%;
    margin-top:-10%;
    margin-left:10%;
  }
  @media (max-width: 325px) {
    height:60%;
    margin-top:30%;
  } 
`
const Prize = styled.div`
  width:6%;
  height:11%;
  border-radius:50%;
  background-color:black;
  position:absolute;
  top:72%;
  left:17.5%;
  border:none;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  z-index:1;
  @media (max-width: 820px) {
    width:20%;
    height:9%;
    top:39%;
    left:12%;
    display:none;
  }
`
const Value = styled.p`
  font-size:1rem;
  color:white;
`
const TextValue= styled.p`
  font-size:1rem;
  color:#02ff79;
  margin-top:-15%;
`
const TitleRight= styled.h5`
  font-size:1rem;
  color:white;
  font-weight:900;
  margin-bottom:2%;
`
const ContainerTextRight= styled.div`
  width:95%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  margin-top:-10%;
  
  @media (max-width: 325px) {
    margin-top:-50%;
  } 
`
const WeekTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction:row;
  align-items: center;
  color:white;
  font-weight:bold;
  padding: 1rem;
  margin-top:2%;
  width:25%;
  @media (max-width: 820px) {
    width:70%;
  }
`;

const ContainerEmptyRaffles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`
const EmptyText = styled.h2`
  color: white;
  font-weight:900;
`

const Weekly = () => {
  const [weeks, setWeeks] = useState<string[]>([])
  const [raffleData, setRaffleData] = useState<RaffleProps[]>([])
  const [raffleName, setRaffleName] = useState<string>("")
  const [raffleImage, setRaffleImage] = useState<string>("")
  const [winners, setWinners] = useState<Winners[]>([])
  const [isWinner, setIsWinner] = useState<boolean>(false)
  const {user} = useSelector((state: any) => state.auth)
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);

  const handleClickPrevious = () => {
    if (currentWeekIndex > 0) {
      setCurrentWeekIndex(currentWeekIndex - 1);
    }
  }

  const handleClickNext = () => {
    if (currentWeekIndex < weeks.length - 1) {
      setCurrentWeekIndex(currentWeekIndex + 1);
    }
  }

  const handleAvailableDates = async() =>{
    const response = await raffleService.fetchAvailableDates(user.owner.wallet, user.owner._id)
    if(!response) return;
    setWeeks(response.dates)
  }

  const handleFetchRaffle = async() => {
    const response = await raffleService.fetchRaffleByDate(user.owner.wallet, user.owner._id,weeks[currentWeekIndex])
    if(!response) return;
    setRaffleData(response.raffle)
    setWinners(response.raffle.winners)
    setIsWinner(response.isWinner)
    setRaffleName(response.raffle.name)
    setRaffleImage(response.raffle.imagePath)
  }

  useEffect(() =>{
    handleAvailableDates();
  },[])
  
  useEffect(() => {
    if(weeks.length > 0) {
      handleFetchRaffle();
    }
  }, [currentWeekIndex, weeks])
  return (
    <>
    <WeekTopContainer>
      {currentWeekIndex > 0 ? 
        <img style={{cursor: 'pointer'}} src={leftIconActive} onClick={handleClickPrevious} alt="previous week" /> 
        :
        <img style={{cursor: 'pointer'}}  src={leftIconInactive} alt="previous week" />
      }
      <p>{weeks[currentWeekIndex]}</p>
      {currentWeekIndex < weeks.length - 1 ? 
        <img style={{cursor: 'pointer'}} src={rightIconActive} onClick={handleClickNext} alt="next week" /> 
        :
        <img style={{cursor: 'pointer'}} src={rightIconInactive} alt="next week" />
      }
      </WeekTopContainer>
    <WeekContainer>
        <Week>
          {
            raffleData.length === 0 &&
            <ContainerEmptyRaffles>
              <EmptyText>NEXT RAFFLE ON 17.06.2023</EmptyText>
            </ContainerEmptyRaffles>
          }
          {raffleData.length !==0 && 
            <>
              <ContainerOffer>
                <TextPrize>
                  <SubTitle>PRIZE</SubTitle>
                  <Title>{raffleName}</Title>
                </TextPrize>
                <ContainerImg>
                  <ImgBasket src={raffleImage}/>
                </ContainerImg>
                <Prize>
                  <Value>VALUE</Value>
                  <TextValue>€129</TextValue>
                </Prize>
              </ContainerOffer>
              <List>
                <ContainerTextRight>
                  <TitleRight>WINNERS</TitleRight>
                </ContainerTextRight>
                <LittleCards winners={winners!} isWinner={isWinner}/>
              </List>
            </>
          }
        </Week>
    </WeekContainer> 
    <FooterNotHome/>
</>
  )
}

export default Weekly