import React from 'react'
import styled from 'styled-components'
import RewardList from './RewardList'
import FooterNotHome from '../Footer/FooterNotHome'

const All = styled.div`
  margin-bottom:-29%;
  margin-top:1%;
`
const Rewards = () => {
  return (
    <>
    <All>
    <RewardList/>
    </All>
   
    </>
  )
}

export default Rewards