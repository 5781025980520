import React from "react";
import styled from "styled-components";
import PopUp from "../CollectionComponents/PopUp";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import BackLog from "../Assets/backLog.png"


const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  background-image: url(${BackLog});
  background-repeat: norepeat;
  background-size: cover;
  z-index: 1;

`;

const VerifierMultiHolder = () => {
    return(
        <>
        <Overlay>
            <PopUp/>
        </Overlay>
        </>
    )

}

export default VerifierMultiHolder;