import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/NavbarHome'
import Landing from '../HomeComponents/Landing'
import Cards from '../HomeComponents/Cards'
import History from '../HomeComponents/History'
import Question from '../HomeComponents/Question'
import JoinUs from '../HomeComponents/JoinUs'
import FoireAQuestion from '../HomeComponents/FoireAQuestion'
import Footer from '../Footer/Footer'
import styled from 'styled-components'
import Vector2 from "../Assets/Vector2.png"
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { clearErrorMessage, loginByWallet } from '../app/redux/slices/authSlice'
import { useLocation } from 'react-router-dom'
import { getLocalStorageValue } from '../utils/localStorage.utils'
import LoaderHome from './LoaderHome'

const BackImg = styled.div`
    width:100%;
    margin-top:-15%;
    background-image: url(${Vector2});
    height:40vh;
    background-repeat: no-repeat;
    @media (max-width: 800px) {
      height:30vh;
      margin-top:-30%;
      background-size:cover;
    }
`



const Home = () => {
  const {address} = useAccount()
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation()
  const {disconnect} = useDisconnect()
  const {isLoadingOk, errors, isAuth, user} = useSelector((state) => state.auth);
  const { data, error, isLoading, signMessage, variables } = useSignMessage()
  const [originalMessage, setOriginalMessage] = useState(null);
  const [originalDate, setOriginalDate] = useState(null);

  useEffect(()=>{
    if(address && !data && !error && !isLoading){
      //dispatch(loginByWallet(address))
      const appName = "The Mission - UNDW3"
      const currentDateTime = new Date().toLocaleString()
      console.log("Current time", currentDateTime)
      const message = `I am verifying my ownership of this account with ${appName}. This message is for verification purposes only and does not grant any access to my account. ${currentDateTime}`;
      setOriginalMessage(message)
      setOriginalDate(currentDateTime)
      signMessage({message})
    }
  },[address])


  useEffect(() =>{
    if(address && data && originalMessage && originalDate) {
      const objectToPass = {
        address,
        data,
        originalMessage,
        originalDate
      }
      console.log(address, "Data", data, originalMessage, originalDate)
      dispatch(loginByWallet(objectToPass))
    }
  },[address,data,originalDate, originalMessage])

  // useEffect(() =>{
  //   if(error){
  //     alert(error.message)
  //   }
  // },[error])

  useEffect(() => {
      if (errors) {
      setTimeout(() => {
          dispatch(clearErrorMessage());
      }, 3500);
      }
  }, [errors, dispatch]
  );

  useEffect(() => {
    console.log(errors)
      if (errors === "You are not owner of this collection") {
              setTimeout(() => {
                  dispatch(clearErrorMessage());
                  //window.location.href = "https://opensea.io/collection/lacoste-undw3-genesis-pass"
              }, 3500);
          }
      }, [errors, dispatch]
  );

  useEffect(() => {
    const from  = location.state?.from || '/collection'
    if(isAuth && address &&  user.owner.isMultiHolder && !user.owner.choosenTokenId ) navigate("/multiholder");
    if(isAuth && address && user.owner.choosenTokenId && !user.owner.discord_userName) navigate("/discord"); //redirect to discord login
    if(isAuth && address && user.owner.choosenTokenId && user.owner.discord_userName) navigate(from)

  }, [isAuth, navigate]);


  return (
    <>
    {isLoadingOk && <LoaderHome title='Loading...'/> }
    {isLoading && <LoaderHome title='Waiting for message signing...'/>}
    {errors === "You are not owner of this collection" && <LoaderHome title='You are not owner of this collection'/>}
    <Navbar/>
    <Landing/>
    <Cards/>
    <History/>
 
    <FoireAQuestion/>
    <BackImg/>
    <Footer/>
   
    </>
  )
}

export default Home