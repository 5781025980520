import React from 'react'
import styled from 'styled-components'
import { useState } from "react";
import RankOn from './RankOn';
import RankingOff from './RankingOff';
import CustomButton from './CustomButton';

const GlobalAll = styled.div`
display:flex;
height:100vh;
`
const GlobalRanking = () => {
  return (
    <GlobalAll>
    <CustomButton/>
    </GlobalAll>
  )
}

export default GlobalRanking


