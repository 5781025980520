import React from 'react'
import styled from 'styled-components'
import ScrollGif from "../Assets/Fleche.svg" 
import BackLanding from "../Assets/BackLanding.png"
import Vector from "../Assets/Vector.png"

const LandingPage = styled.div`
  width:100%;
  height:85vh;
  margin-top:2%;
  overflow-x: hidden;
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align:center;
  justify-content:center;
 
  
  @media (max-width: 820px) {
    overflow-x: hidden;
  }
  @media (max-width: 375px) {
    height:90vh;
  }    
  @media (max-width: 325px) {
    height:100vh;
  }    
  @media (device-width: 320px) and (device-height: 658px){
    height:80vh;
  }   
  @media (device-width: 414px) and (device-height: 736px)      
  {
  height:100vh;
}  
  @media only screen and (min-width: 950px) and (max-width: 1366px) {    
  height:100vh;

}


            
`
const LandingPageImg = styled.img`
  width:100%;
  z-index:1;
  position:absolute;
  overflow-x: hidden;
  top:0;
  @media (max-width: 820px) {
    height:45%;
    object-fit:cover;
    overflow-x: hidden;
  }
`
const LandingPageOverlay = styled.img`
  width:80%; 
  z-index:2;
  position:absolute;
  overflow-x: hidden;
  top:0;
  @media (max-width: 820px) {
    overflow-x: hidden;
      }
`

const TitleLanding = styled.h1`
  z-index:4;
  align-items:center;
  text-align:center;
  letter-spacing:-4px;
  justify-content:center;
  color:white;
  font-size:5.2rem;
  text-shadow:  rgba(255,255,255,0.9) 0px 0px 6px; 
  @media (max-width: 820px) {
    font-size:3rem;
    overflow-x: hidden;
    margin-top:-15.5%;
  }
 
 
  `
const SubtitleLanding = styled.h2`
  z-index:4;
  align-items:center;
  text-align:center;
  color:white;
  font-size:2.7rem;
  margin-top:-5.5%;
  text-shadow:  rgba(255,255,255,0.9) 0px 0px 6px; 
  //text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.1);
  @media (max-width: 820px) {
    font-size:2rem;

    margin-top:-10.5%;
  }
  @media (max-width: 375px) {
    font-size:1rem;
        }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    margin-top:-5%;
        } 
        @media screen and (max-width: 845px) and (max-height: 1500px) {

          margin-top:-5.5%;
             
             }             
  
`

const TextLanding = styled.h4`
  z-index:4;
  align-items:center;
  text-align:center;
  color:white;
  font-size:0.9rem;
  font-weight: 500;
  width:65%;
  text-shadow:  rgba(255,255,255,0.9) 0px 0px 3px; 
  //text-shadow: 0 0 10px rgba(255, 255, 255, 0.2), 0 0 20px rgba(255, 255, 255, 0.2), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.2), 0 0 70px rgba(255, 255, 255, 0.1), 0 0 80px rgba(255, 255, 255, 0.1), 0 0 100px rgba(255, 255, 255, 0.2), 0 0 150px rgba(255, 255, 255, 0.1);
  @media (max-width: 820px) {
    font-size:1rem;
    width:85%;
  }
  @media (max-width: 325px) {
    font-size:0.8rem;
    width:90%;
  }

  `
const GetYourPassButton = styled.button`
z-index:4;
  height:8%;
  transform:scale(0.88);
  font-weight:600;
  border-radius: 25px;
  padding: 15px 20px 15px 20px;
  background-color:#02ff79;
  color: black;
  font-weight:700;
  text-align: center;
  cursor:pointer;
  border:none;
 
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  &:hover{
    background-color: #fff; 
    box-shadow: 0 0 10px #02ff79; 
  }
  @media (max-width: 820px) {
    transform:scale(0.85);
    padding: 10px 55px 10px 55px;
    height:7%;
    font-size:1rem;
    border-radius: 25px;

  }
`
const GifAndText = styled.div`
z-index:4;
  display:flex;
  margin-top:5%;
  flex-direction:column;
  align-items:center;
  justify-content:center;
`
const GifImage = styled.img`
z-index:4;
  width: 40%;
  height: auto;
  margin-top:-30%;
`;

const TitleGif = styled.h6`
  z-index:4;
  align-items:center;
  text-align:center;
  color:white;
`

const Landing = () => {
  return (
    <LandingPage>
      <LandingPageImg src={BackLanding}/>
      <LandingPageOverlay src={Vector}/>
        <TitleLanding>THE MISSION&nbsp; </TitleLanding>
        <SubtitleLanding>LACOSTE UNDW3 CARD</SubtitleLanding>
        <TextLanding>
              Experience a new interactive UNDW3 adventure and evolve your Lacoste UNDW3 Card. Each UNDW3 chapter is directly linked to your commitment. Get ready to solve quests, engage with Lacoste, and unlock exclusive UNDW3 benefits.
        </TextLanding>
        <GetYourPassButton>START NOW</GetYourPassButton>
        <GifAndText>
            <TitleGif>SCROLL</TitleGif>
            <GifImage src={ScrollGif} alt="GIF image"/>
          </GifAndText>
         
    </LandingPage>
  )
}

export default Landing