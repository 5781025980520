import React from "react";
import PopUpDiscord from "../CollectionComponents/PopUpDiscord";
import styled from "styled-components";
import BackLog from "../Assets/backLog.png"


const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  background-image: url(${BackLog});
  background-repeat: norepeat;
  background-size: cover;
  z-index: 1;

`;


const VerifierDiscord = () => {
    return(
        <>
        <Overlay>
            <PopUpDiscord/>
        </Overlay>
        </>
    )
}


export default VerifierDiscord;