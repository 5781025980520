import React from "react";
import Coin from "../Assets/Coin.png"
import MetaWallet from "../Assets/icon-metamask.svg"
import CoinbaseWallet from "../Assets/coinbase-logo.svg"
import WalletConnect from "../Assets/wallet-connect-logo.svg"

function getWalletLogo(providerName: string) {
    switch (providerName) {
      case 'MetaMask':
        return MetaWallet;
      case 'Coinbase Wallet':
        return CoinbaseWallet;
      // add more cases as needed...
      default:
        return WalletConnect; // or a default logo
    }
}

export default getWalletLogo