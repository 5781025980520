import React from 'react'
import styled,{keyframes, css} from 'styled-components'
import Discord from "../Assets/DiscordLogo.svg"
import { Waypoint } from 'react-waypoint';
import { useState } from 'react';

const ShakeAnimation = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;


const JoinUsPageContainer = styled.div`
  
    width:100%;
    height:15vh;
    display:flex;
    align-items:center;
    justify-content:center;
    @media (max-width: 820px) {
    width:100%;
      margin-top:-42%;
     }
    @media (max-width: 325px) {
        height:50vh;
               }
    @media (device-width: 414px) and (device-height: 896px){
        margin-top:-42%;
        margin-bottom:15%;
        height:25vh;
                }     
    @media (device-width: 412px) and (device-height: 915px){
        margin-top:-60%;
        height:35vh;
                }       
    @media (device-width: 375px) and (device-height: 667px){
        margin-top:-60%;
        height:40vh;
                 }     
    @media (device-width: 414px) and (device-height: 736px){
        margin-top:-35%;
        height:45vh;
                    } 
    @media (device-width: 360px) and (device-height: 740px){
        height:40vh;
        margin-top:-55%;
                     } 
    @media (device-width: 390px) and (device-height: 844px){
            height:40vh;
    } 
    @media only screen and (device-width: 768px) and (device-height: 1024px)  {
        height: 300px;
    }                 
    
    @media only screen and (device-width: 820px) and (device-height: 1180px)  {
        height: 280px;
    } 
                                                                
` 
const JoinUsContainer = styled.div<{isInView: boolean}>`
    width:65%;
    height:20vh;
    border: 2px solid #02ff79;
    border-radius: 5px; 
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px; 
    border-bottom-left-radius: 10px; 
    border-bottom-right-radius: 10px; 
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-around;
    animation: ${props => props.isInView ? css`${ShakeAnimation} 0.82s cubic-bezier(.36,.07,.19,.97) both` : 'none'};
    @media (max-width: 820px) {
        width:88%;
        height:25vh;
        flex-direction:column;
        justify-content:center;
        padding:2%;
        border-top-left-radius: 15px; 
        border-top-right-radius: 15px; 
        border-bottom-left-radius: 15px; 
        border-bottom-right-radius: 15px; 
    }
    @media (max-width: 325px) {
        height:40vh;
    }
    @media (device-width: 375px) and (device-height: 667px){
        width:80%;
        padding:4%;
    } 
   @media only screen and (device-width: 768px) and (device-height: 1024px)  {
       width:70%;
    }          
` 
const DiscordImg = styled.img`
    
    @media (max-width: 820px) {   

    }
    @media (max-width: 325px) {
     
    }
    @media (device-width: 375px) and (device-height: 667px){
    
     
    } 
   @media only screen and (device-width: 768px) and (device-height: 1024px)  {
    
    }           
`
const DiscordText = styled.h1`
    width:100%;
    color:white;
    margin-left:5%;
    text-shadow:0.1px 0.1px 0.1px #fff, 0.1px 0.1px 5px #ccc;
    font-size: 2.5rem;
    line-height:1em;
    @media (max-width: 820px) {
        font-size: 1.7rem;
        width:45%;
        margin-right:10%;
        line-height:0.9em;
        margin-left:0%;
    }
`
const ButtonCall = styled.button`
    height:9%;
    width:15%;
    font-weight:600;
    border-radius: 25px;
    padding: 20px;
    background-color:#02ff79;
    align-items:center;
    justify-content:center;
    display:flex;
    color: black;
    font-weight:600;
    font-size:0.76rem;
    cursor:pointer;
    border:none;
    box-shadow: 0 0 5px #007bff; 
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    &:hover{
      background-color: #fff; 
      box-shadow: 0 0 10px #02ff79; 
    }
    @media (max-width: 820px) {
        width:65%;
        font-size:0.9rem;
    }
    
`
const TextAndLogo = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    width:60%;
    flex-direction:row;
    @media (max-width: 820px) {
        width:100%;
        justify-content:center;  
    }
    @media only screen and (device-width: 768px) and (device-height: 1024px)  {
        margin-left:10%;
    }  
`
const JoinUs = () => {
    const [isInView, setIsInView] = useState(false);
    const onEnterViewport = () => {
        setIsInView(true);
    };
    const handleOpenDiscord = () =>{
        window.open('https://discord.com/invite/undw3', '_blank', 'noopener,noreferrer')
    }
  return (
    <JoinUsPageContainer>
        <Waypoint onEnter={onEnterViewport}>
            <JoinUsContainer isInView={isInView}>
            <TextAndLogo>
                <DiscordImg src={Discord}/>
                <DiscordText>JOIN THE MISSION ON DISCORD</DiscordText>
            </TextAndLogo>
            <ButtonCall onClick={handleOpenDiscord}>JOIN US</ButtonCall>
        </JoinUsContainer>
        </Waypoint>
        
    </JoinUsPageContainer>
  )
}

export default JoinUs