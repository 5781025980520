import React from 'react'
import Podium2 from './Podium2'
import styled from 'styled-components'
import ListPodium from './ListPodium'
import FooterNotHome from '../Footer/FooterNotHome'

const Containercard = styled.div`
  width:100%;
  flex-direction:column;
  height:60vh;
   display:flex;

 
@media (max-width: 820px) {
    margin-top:35%;
        
                  }  
@media (max-width: 325px) {
    margin-top:3%;
                
                          }  
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
  margin-top:25%;
               } 

    
`
const ContainerAll = styled.div`
  width:100%;
  flex-direction:column;
 
`
const RankOn = () => {
  return (
<>
    <Containercard>
    <Podium2/>
    <ListPodium/>
    </Containercard>
   

</>
  )
}

export default RankOn