import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import raffleService from '../services/raffles.service'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


const ContainerLayer = styled.div`
  justify-content:center;
  align-items:center;
    display:flex;
    flex-direction:row;
    position:absolute;
    top:0;
    width:100%;
    height:7vh;
    z-index:1;
    background-color:#02ff79;
    @media (max-width: 820px) {
        height:6vh;
        
           }



`
const TextGauche = styled.p`
    color:black;
    align-items:center;
    letter-spacing:1px;
    font-size:1.3rem;
    @media (max-width: 820px) {
       font-size:0.7rem;
       margin-top:5%;
          }
          @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
            margin-top:3%;
            font-size:1rem;
                   } 

`
const TextDroite = styled.p`
    color:black;
    align-items:center;
    font-weight:700;
    font-size:1.3rem;
    @media (max-width: 820px) {
        font-size:0.7rem;
        margin-top:-4%;
           }
           @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
            margin-top:-2%;
            font-size:1rem;
                   } 
`
const TextAll = styled.div`
  display:flex;
  @media (max-width: 820px) {
      flex-direction:column;
      align-items:start;
      justify-content:center;
      width:70%;
  }
  @media (max-width: 325px) {
    padding-top: 5px;
    padding-bottom: 5px;
}
`
const LayerButton = styled.button`
 
    font-weight:600;
    border-radius: 25px;
    display:flex;
    padding: 13px 15px 13px 15px; 
    background-color:black;
    color: white;
    font-weight:600;
    font-size:0.7rem;
    align-items:center;
    text-align:center;
    cursor:pointer;
    border:none;
    transform:scale(0.9);
    @media (max-width: 325px) {
        transform:scale(0.5);
    } 
    @media (max-width: 375px) {
      transform:scale(0.65);
  } 
`

const Layer = () => {
  const [nextRaffleName, setNextRaffleName] = useState<string>("")
  const {user} = useSelector((state: any) => state.auth)
  const navigate = useNavigate()

  const handleFetchRaffleName = async() =>{
    const response = await raffleService.fetchNextRaffleName(user.owner.wallet, user.owner._id)
    if(!response) return
    setNextRaffleName(response.nextRaffleName)
  }

  const handleNavigate = () =>{
    navigate("/results")
  }

  useEffect(()=>{
    handleFetchRaffleName()
  },[])
  return (
    <ContainerLayer>
        <TextAll>
        <TextGauche>
        &nbsp; NEXT RAFFLE : 
        </TextGauche>
        <TextDroite>
            &nbsp; {nextRaffleName} TO WIN! &nbsp;
        </TextDroite>
        </TextAll>
        <LayerButton onClick={handleNavigate}>
            See Previous Results
        </LayerButton>
    </ContainerLayer>
  )
}

export default Layer